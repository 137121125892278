import React, { useEffect, useState } from "react";
import "../../Styles/newpage.css";
import { useSelector } from "react-redux";
import UserProfileUpdate from "./UserProfileUpdate";
import { useDispatch } from "react-redux";
import { Userprofile } from "../../Redux/Slice/ChatUserSlice";
import Swal from "sweetalert2";
import { deleteRoom } from "../../Redux/Slice/ChatUserSlice";
import { ReportUSer } from "../../Redux/Slice/ChatUserSlice";
import { BlockUser } from "../../Redux/Slice/ChatUserSlice";
import { unblockUser } from "../../Redux/Slice/ChatUserSlice";
import { groupUserDetails } from "../../Redux/Slice/ChatUserSlice";
import { DeleteAccountReq } from "../../Redux/Slice/ChatUserSlice";
import { MuiOtpInput } from "mui-one-time-password-input";
import { DeleteAccount } from "../../Redux/Slice/ChatUserSlice";
import { json, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/Images/defaultImage.png";
import { addMember } from "../../Redux/Slice/ChatUserSlice";
import Alert from "@mui/material/Alert";
import { GroupProfieUpdate } from "../../Redux/Slice/ChatUserSlice";

const ProfileSection = ({
  showClientInfo,
  clientInfo,
  setShowClientInfo,
  setClientInfo,
  setDeleteMyAccount,
  deleteMyAccount,
  setShowEditProfile,
  showEditProfile,
}) => {
  const [otp, setOtp] = React.useState("");
  const [showPop, setShowPop] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const dispatch = useDispatch();
  const [blockList, setBlockList] = useState([]);
  const [deleteOtp, setdeleteOtp] = useState(false);
  const [hummingContactsDetails, setHummingAllContactsDetails] = useState([]);
  const [addMembers, setAddMembers] = useState(false);
  const userInfoGet = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const [selectedDetails, setSelectedDetails] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [checkAddPerson, setCheckAddPerson] = useState(false);
  const [groupId, setGroupId] = useState("");

  const navigate = useNavigate();

  const {
    profile,
    Paticulargroupdetails,
    DeleteAccountResponce,
    DeletedAccountResponce,
  } = useSelector((state) => ({
    profile: state.user.Userprofile?.user,
    Paticulargroupdetails: state.user?.groupUserDetails,
    DeleteAccountResponce: state.user?.DeleteAccountResponce,
    DeletedAccountResponce: state.user?.DeletedAccountResponce,
  }));
  const [groupName, setGroupName] = useState(
    Paticulargroupdetails?.room?.r_name
  );
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    const LoginUserId = localStorage.getItem("m_sender_id");
    if (profile && profile?.user_id && profile?.user_id === LoginUserId) {
      localStorage.setItem("userInfo", JSON.stringify(profile));
    }
  }, [profile]);

  useEffect(() => {
    if (userInfoGet && userInfoGet.u_block_list) {
      const parsedBlockList = JSON.parse(userInfoGet.u_block_list);
      setBlockList(parsedBlockList);
    }
  }, []);

  useEffect(() => {
    if (showClientInfo && clientInfo?.UserId) {
      dispatch(Userprofile(clientInfo.UserId));
    }
  }, [showClientInfo, clientInfo, dispatch]);

  const BASE_URL = "https://myhummingbird.net";
  const imageUrl = `${BASE_URL}${profile?.user_image}`;
  const groupImage = `${BASE_URL}${Paticulargroupdetails?.room?.r_avatar}`;
  const [groupImageEdit, setGroupImageEdit] = useState(groupImage);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const closeDetails = () => {
    setShowDetails(false);
  };

  const closeGroupDetails = () => {
    setShowClientInfo(false);
  };

  const handleEditProfile = () => {
    setShowEditProfile(true);
    setShowDetails(false);
    setDeleteMyAccount(false);
  };

  const handleProfileUpdateClose = () => {
    setShowEditProfile(false);
  };

  const handleHover = () => {
    setShowPop(true);
  };

  const handleLeave = () => {
    setShowPop(false);
  };

  const handleClientModal = () => {
    let user_id = localStorage.getItem("m_sender_id");
    setShowClientInfo(false);
    dispatch(Userprofile(user_id));
  };

  // delete room
  const handleDeleteRoom = (RoomId) => {
    console.log(56, RoomId);
    Swal.fire({
      title: "Are you sure?",
      text: "It will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRoom(RoomId))
          .then(() => {
            Swal.fire("Deleted!", "Your room has been deleted.", "success");
            // Optionally update the local state or Redux state to remove the room
          })
          .catch((error) => {
            Swal.fire(
              "Error!",
              "There was a problem deleting the room.",
              "error"
            );
          });
      }
    });
  };

  // Report paticular person start

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const handleReportRoom = (UserId) => {
    Swal.fire({
      title: "Describe your issue in 150 words...",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Report Now",
      showLoaderOnConfirm: true,
      preConfirm: (username) => {
        if (!username) {
          Swal.showValidationMessage("You need to enter a Report Message ");
        }
        return username;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(ReportUSer({ UserId: UserId, reportMsg: result.value }));
        swalWithBootstrapButtons.fire({
          title: "Reported !",
          text: "You successfully reported this user .",
          icon: "success",
        });
      }
    });
  };

  // Report paticular person end

  // Block user

  const handleBlockUser = (UserId) => {
    // Simulate API call or dispatch an action to block user
    // Replace with your actual API call logic
    Swal.fire({
      title: "Are you sure?",
      text: `You want to block ${clientInfo?.UserName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, block it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(BlockUser(UserId));
        setTimeout(() => {
          const updatedBlockList = [...blockList];
          if (!updatedBlockList.includes(UserId)) {
            updatedBlockList.push(UserId);
            setBlockList(updatedBlockList);

            // Update localStorage with the updated block list
            localStorage.setItem(
              "userInfo",
              JSON.stringify({ u_block_list: JSON.stringify(updatedBlockList) })
            );

            Swal.fire(
              "Blocked!",
              ` ${clientInfo?.UserName} blocked successfully.`,
              "success"
            );
          } else {
            Swal.fire(
              "Already Blocked!",
              `User ${clientInfo?.UserName} is already blocked.`,
              "info"
            );
          }
        }, 1000); // Simulate API call delay of 1 second (1000 ms)
      }
    });
  };

  // block user end

  // Unblock User
  const handleUnBlockUser = (UserId) => {
    // Simulate API call or dispatch an action to unblock user
    // Replace with your actual API call logic
    Swal.fire({
      title: "Are you sure?",
      text: `You want to unblock ${clientInfo?.UserName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unblock it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(unblockUser(UserId));
        setTimeout(() => {
          const updatedBlockList = blockList.filter((id) => id !== UserId);
          setBlockList(updatedBlockList);

          // Update localStorage with the updated block list
          localStorage.setItem(
            "userInfo",
            JSON.stringify({ u_block_list: JSON.stringify(updatedBlockList) })
          );

          Swal.fire(
            "Unblocked!",
            ` ${clientInfo?.UserName} unblocked successfully.`,
            "success"
          );
        }, 1000); // Simulate API call delay of 1 second (1000 ms)
      }
    });
  };

  const isBlocked = blockList.includes(clientInfo?.UserId);

  // Fetch User Group Details

  useEffect(() => {
    if (showClientInfo && clientInfo?.room_id) {
      dispatch(groupUserDetails(clientInfo?.room_id));
    }
  }, [showClientInfo, clientInfo, dispatch]);

  const deletereq = () => {
    setDeleteMyAccount(false);
    setClientInfo(false);
  };

  const deleterequest = () => {
    Swal.fire({
      title: "Request",
      text: `Send Delete Account Request`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Request send!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteAccountReq());
        setDeleteMyAccount(false);
        setdeleteOtp(true);
        setTimeout(() => {
          Swal.fire("Delete !", ` request send successfully.`, "success");
        }, 1000); // Simulate API call delay of 1 second (1000 ms)
      }
    });
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // verifyOtp

  const verifyOtp = () => {
    dispatch(DeleteAccount());
    setdeleteOtp(false);
  };

  // all Contacts

  useEffect(() => {
    try {
      const storedMatchedContacts =
        JSON.parse(localStorage.getItem("matchedContacts")) || [];
      if (storedMatchedContacts?.length > 0) {
        setHummingAllContactsDetails(storedMatchedContacts);
      }
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
    }
  }, []);

  // check login user admin or not
  const LoginUserId = localStorage.getItem("m_sender_id");
  let userAdmin = false;
  const adminCheck = Paticulargroupdetails?.room?.r_admins;
  if (adminCheck) {
    const admins = JSON.parse(adminCheck);
    let isAdmin = admins.includes(LoginUserId);
    if (isAdmin) {
      userAdmin = true;
    }
  }

  // add memberfuntionlity

  const addMembersOpen = () => {
    setShowClientInfo(false);
    setAddMembers(true);
  };

  const handleMemberClick = (details, index) => {
    if (selectedIndices.includes(index)) {
      // If the contact is already selected, remove it from the selection
      setSelectedDetails((prevSelected) =>
        prevSelected.filter((_, i) => i !== index)
      );
      setSelectedIndices((prevIndices) =>
        prevIndices.filter((i) => i !== index)
      );
    } else {
      // If the contact is not selected, add it to the selection
      setSelectedDetails((prevSelected) => [...prevSelected, details]);
      setSelectedIndices((prevIndices) => [...prevIndices, index]);
    }
  };

  const addMembeGroup = () => {
    const jsonSelectedDetails = JSON.stringify(selectedDetails);

    if (selectedDetails.length > 0) {
      console.log("selectedDetails", jsonSelectedDetails);
      dispatch(
        addMember({
          jsonSelectedDetails: jsonSelectedDetails,
          roomId: Paticulargroupdetails?.room?.r_id,
        })
      );
      setCheckAddPerson(false);
    } else {
      setCheckAddPerson(true);
    }
  };

  // edit profile group
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setGroupImageEdit(event.target.result);
        var body = {
          room_id : groupId ,
          room_name : groupName ,
          group_image : e.target.files
        }
        dispatch(GroupProfieUpdate(body))
      };
      reader.readAsDataURL(file);
    }
  };

  const toggleEditName = () => {
    console.log("isEditingName", isEditingName);
    setIsEditingName(!isEditingName);
  };

  const saveGroupName = () => {
    console.log("saveGroupName", groupName);
    var body = {
      room_id : groupId ,
      room_name : groupName ,
      group_image : groupImageEdit
    }
    dispatch(GroupProfieUpdate(body))
    setIsEditingName(false);
    // Add your save logic here
  };

  useEffect(() => {
    console.log("Paticulargroupdetails", Paticulargroupdetails?.room?.r_created_at);
    if (Paticulargroupdetails) {
      setGroupId(Paticulargroupdetails?.room?.r_id);
      setGroupImageEdit(`${BASE_URL}${Paticulargroupdetails?.room?.r_avatar}`);
      setGroupName(Paticulargroupdetails?.room?.r_name);
    }
  }, [Paticulargroupdetails]);

  return (
    <div className="p-3 mt-0 overflow-auto " style={{ width: "450px" }}>
      {!showClientInfo && (
        <>
          <div
            className="profile-card"
            onClick={toggleDetails}
            onMouseEnter={handleHover}
            onMouseLeave={handleLeave}
          >
            <div className="profile-image">
              <img src={imageUrl} alt="Profile Image" className="h-100 w-100" />
            </div>
            <div className="profile-info">
              <div className="profile-name">
                {profile?.user_first_name} {profile?.user_last_name}
              </div>
              <div className="d-flex justify-content-between">
                <div className="profile-description mt-0 d-flex text-success fw-semibold">
                  91+{profile?.user_mobile_num}
                </div>
                <div className="profile-description mt-0 d-flex text-success fw-semibold ml-2">
                  {/* 91+{profile?.user_mobile_num} */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {showDetails && !showClientInfo && (
        <div className="custom-profile-card">
          <div
            className="btn d-flex justify-content-end mb-1 text-danger fs-2"
            onClick={closeDetails}
          >
            ×
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="custom-icon custom-icon-username mr-1 fs-6 ml-0">
              ABC
            </div>
            <div>
              <h5 className="mb-0">Username</h5>
              <p className="mb-0">
                {profile?.user_first_name} {profile?.user_last_name}
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="custom-icon custom-icon-phone mr-3">📞</div>
            <div>
              <h5 className="mb-0">Phone</h5>
              <p className="mb-0">{profile?.user_mobile_num}</p>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="custom-icon custom-icon-email mr-3">✉️</div>
            <div>
              <h5 className="mb-0">Email</h5>
              <p className="mb-0">{profile?.user_email}</p>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            <div className="custom-icon custom-icon-bio mr-3">ℹ️</div>
            <div>
              <h5 className="mb-0">BIO</h5>
              <p className="mb-0">{profile?.user_bio}</p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="custom-icon custom-icon-dob mr-3">📅</div>
            <div>
              <h5 className="mb-0">Date Of Birth</h5>
              <p className="mb-0">{profile?.user_dob}</p>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-100 mt-4"
            onClick={handleEditProfile}
          >
            Edit Profile
          </button>
        </div>
      )}

      {showEditProfile && !showClientInfo && (
        <UserProfileUpdate
          profile={profile}
          onClose={handleProfileUpdateClose}
        />
      )}

      {showClientInfo && clientInfo?.roomType === "0" && (
        <>
          <div class="container mt-5 h-100">
            <div class="card user-info-card mx-auto p-3">
              <div class="d-flex justify-content-between align-items-center">
                <h4>User Info</h4>
                <button
                  type="button"
                  class="close text-danger mb-4"
                  aria-label="Close"
                  onClick={handleClientModal}
                ></button>
              </div>
              <div class="text-center">
                <img
                  src={imageUrl}
                  alt="User Image"
                  class="rounded-circle user-image"
                />
                <h5 class="mt-3">{clientInfo?.UserName}</h5>
                <p class="text-muted">{profile?.user_bio}</p>
              </div>
              <div class="d-flex justify-content-around mt-3">
                <button class="btn btn-outline-secondary">
                  <i class="fas fa-share-alt"></i> Share Contact
                </button>
                <button class="btn btn-outline-secondary">
                  <i class="fas fa-bell-slash"></i> Mute Notifications
                </button>
              </div>
              <div class="text-center mt-3">
                <p class="mb-0">
                  <a href="tel:+917999645106">+91 {profile?.user_mobile_num}</a>
                </p>
              </div>
              <div class="mt-3">
                <button
                  class="btn btn-danger btn-block"
                  onClick={() => handleDeleteRoom(clientInfo?.room_id)}
                >
                  <i class="fas fa-trash-alt"></i> Delete Chat
                </button>
                {isBlocked ? (
                  <button
                    className="btn btn-info btn-block"
                    onClick={() => handleUnBlockUser(clientInfo?.UserId)}
                  >
                    <i className="fas fa-ban"></i> Unblock{" "}
                    {clientInfo?.UserName}
                  </button>
                ) : (
                  <button
                    className="btn btn-warning btn-block"
                    onClick={() => handleBlockUser(clientInfo?.UserId)}
                  >
                    <i className="fas fa-ban"></i> Block {clientInfo?.UserName}
                  </button>
                )}
                <button
                  class="btn btn-secondary btn-block"
                  onClick={() => handleReportRoom(clientInfo?.UserId)}
                >
                  <i class="fas fa-flag"></i> Report {clientInfo?.UserName}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {showClientInfo && clientInfo?.roomType === "1" && (
        <div class="container mt-4">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <i
              className="fa fa-close text-danger fw-bold mb-4"
              onClick={closeGroupDetails}
            ></i>

            <button class="btn btn-primary">Save</button>
          </div>

          <div class="text-center">
            {userAdmin ? (
              <div className="avatar-upload mb-0">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                    onChange={(e) => handleImageChange(e)}
                  />
                  <label htmlFor="imageUpload"></label>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${groupImageEdit})` }}
                  ></div>
                </div>
              </div>
            ) : (
              <img
                className=" rounded-circle group-icon"
                src={groupImageEdit}
                alt="Group Icon"
                style={{ width: "160px", height: "160px" }}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = defaultImage;
                }}
              />
            )}
            {isEditingName ? (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <input
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  className="form-control"
                  placeholder="Enter Group Name"
                />
                <button
                  className="btn btn-primary ml-2"
                  onClick={saveGroupName}
                >
                  Save
                </button>
              </div>
            ) : (
              <div className="d-flex align-items-center justify-content-center mt-2">
                <h3 className="mb-0">{groupName}</h3>
                {userAdmin && (
                  <i
                    className="fa fa-edit ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={toggleEditName}
                  ></i>
                )}
              </div>
            )}
            
            <p>Created on 06 Jul 2024</p>
          </div>

          <div class="d-flex justify-content-around my-4">
            {userAdmin && (
              <button
                className="btn btn-outline-success mr-2 "
                onClick={addMembersOpen}
              >
                <i className="fa-solid fa-person-circle-plus mr-1"></i>Add
                Members
              </button>
            )}
            {/* <button class="btn btn-outline-warning mr-2">
              <i class="fa-solid fa-bell-slash "></i>Mute Notifications
            </button> */}
            <button class="btn btn-outline-danger mr-2">
              <i class="fa-solid fa-person-walking-arrow-right"></i> Exit Group
            </button>
          </div>

          <h4 className=" mb-2">Group Members</h4>
          <ul className="list-group">
            {Paticulargroupdetails?.members?.map((member, index) => {
              const LoginUserId = localStorage.getItem("m_sender_id");
              const GroupUserImage = `${BASE_URL}${member?.user_image}`;

              const contactDetail = hummingContactsDetails?.find(
                (contact) => contact.user_id === member.user_id
              );

              let isAdmin = false;
              const adminCheck = Paticulargroupdetails?.room?.r_admins;
              const data = JSON.parse(adminCheck);
              data?.map((adminId) => {
                if (adminId === member?.user_id) {
                  isAdmin = true;
                }
              });
              
              return (
                <li
                  key={index} // Make sure to use a unique key for each item
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex align-items-center">
                    <img
                      className="rounded-circle member-icon"
                      src={GroupUserImage}
                      alt="Loading.."
                      style={{ width: "60px", height: "60px" }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImage;
                      }}
                    />
                    <div className="ml-3">
                      <h5>
                        {member.user_id === LoginUserId
                          ? "You"
                          : contactDetail
                          ? contactDetail.user_first_name
                          : member.user_mobile_num}
                      </h5>
                      <p className="mb-0 text-muted">{member?.user_bio}</p>
                    </div>
                  </div>
                  {isAdmin && (
                    <span className="badge badge-success badge-pill">
                      Admin
                    </span>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {addMembers && (
        <>
          <div class="member-list">
            <div class="container mt-5">
              {checkAddPerson && (
                <Alert severity="error" className="w-100">
                  Please select at least one member to add.
                </Alert>
              )}
              <i className="fa fa-close text-danger fw-bold  mt-2  p-2 "></i>
              <div class="member-header">
                <h5>Add New Members</h5>
                <button class="btn btn-primary" onClick={addMembeGroup}>
                  Add
                </button>
              </div>
              <div class="p-3">
                <div className="form-group has-search-right">
                  <a href="#" className="form-control-feedback mt-2">
                    <span className="fa fa-search"></span>
                  </a>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Contact"
                  />
                </div>
              </div>
              {hummingContactsDetails?.map((details, index) => {
                const BASE_URL = "https://myhummingbird.net";
                const imageUrl = `${BASE_URL}${details?.user_image}`;
                const isSelected = selectedIndices.includes(index);
                return (
                  <>
                    <div
                      class="member-item"
                      onClick={() =>
                        handleMemberClick(details?.user_mobile_num, index)
                      }
                      style={{
                        backgroundColor: isSelected ? "#b1effe" : "transparent",
                      }}
                    >
                      <img src={imageUrl} alt="Loading.." />
                      <div>
                        <h6>{details?.user_first_name}</h6>
                        <p>{details?.user_mobile_num}</p>
                      </div>
                      {isSelected && (
                        <>
                          {" "}
                          <img
                            style={{ width: "25px", height: "25px" }}
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTAnWczi3EorEomRCfJpKPqp-LKH4joLC-uQg&s"
                          />
                        </>
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </>
      )}

      {/* delete my account */}

      {deleteMyAccount && (
        <>
          <div class="container ">
            <div class="headers">
              <h2>Delete My Account</h2>
              <button
                type="button"
                class="close text-danger mb-4"
                aria-label="Close"
                onClick={() => setDeleteMyAccount(false)}
              ></button>
            </div>
            <div class="mt-3">
              <p>
                <i class="fas fa-exclamation-triangle text-danger"></i>{" "}
                <strong>Deleting your account will:</strong>
              </p>
              <ul>
                <li>
                  Delete your account info, profile photo, and all your
                  schedules
                </li>
                <li>Delete you from all Humming Bird Groups</li>
                <li>
                  Delete your message history on this phone and your iCloud
                  account
                </li>
                <li>Delete any channel that you created</li>
              </ul>
            </div>
            <div class="mt-3">
              <label for="phoneNumber">Your Phone Number</label>
              <div class="fancy">
                <input
                  type="text"
                  id="number-input"
                  value={`${"   91+" + userInfoGet?.user_mobile_num}`}
                />
              </div>
            </div>
            <button class="btn btn-danger mt-3" onClick={deleterequest}>
              Delete My Account
            </button>
            <button class="btn btn-success" onClick={deletereq}>
              Cancel
            </button>
          </div>
        </>
      )}

      {deleteOtp && (
        <>
          <div class="container otpDelete mt-5">
            <h4>Enter OTP</h4>

            <MuiOtpInput
              value={otp}
              onChange={handleChange}
              className=" mt-3  p-5"
            />
            <button
              class="btn btn-info btn-lg align-content-center"
              onClick={verifyOtp}
            >
              Verify OTP
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileSection;


     