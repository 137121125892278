import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { useDispatch } from "react-redux";

const firebaseConfig = {
  apiKey: "AIzaSyA8eMBZvxlugFtXq6zUS7-vW4IhfI17udo",
  authDomain: "humming-bird-ed916.firebaseapp.com",
  projectId: "humming-bird-ed916",
  storageBucket: "humming-bird-ed916.appspot.com",
  messagingSenderId: "25194624225",
  appId: "1:25194624225:web:3a677fa450601a359773a0",
  measurementId: "G-9Z633GX43Y",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const genrateToken = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BMX_JMijpSyJDBtatsMAvOF6U9K0hofp04DLiaLsZjORnZjAQDrr5sqyrZgNXxBDOzrRq7j12HUNPtw2Git5Dww",
      });

      localStorage.setItem("fcmToken", fcmToken);
    } else {
      console.error("Notification permission not granted");
    }
  } catch (error) {
    console.error("Error generating token:", error);
    throw error; // Throw the error to be handled further up the call stack if needed
  }
};
