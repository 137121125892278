import socketIO from "socket.io-client";
import { EventEmitter } from "events";

var roomId;
var data;
var userId = localStorage.getItem("m_sender_id");
console.log(userId);

let socket = null;
console.log("socket");

const user_id = localStorage.getItem("m_sender_id");
const END_point = "https://myhummingbird.net:4000";
socket = socketIO(END_point, {
  transports: ["websocket"],
  autoConnect: false,
  query: { userId: user_id },
});
socket.connect();

export const socketEventEmitter = new EventEmitter();
export const initializeSocket = () => {
  socket.emit("user-online", userId);
  getSocket();
  requestContact();
  allContacts();

  socket.on("all-contacts", (allContact) => {
    localStorage.setItem("contacts", allContact);
  });
};

// export const messageReceive = () => {
socket.on("message-receive", (ReciveMessage) => {
  console.log("message-receive in socket file", ReciveMessage);
  // socket.emit("message-received", ReciveMessage);
  socketEventEmitter.emit("message-received", ReciveMessage);
});
// };

export const getSocket = () => {
  if (!socket) {
    console.log(452, socket);
    throw new Error("Socket not initialized. Call initializeSocket first.");
  }
  return socket;
};

export const joinRoom = (room_id) => {
  socket.emit("join-room", room_id);
  if (room_id != roomId) {
    socket.emit("leave-room", roomId);
  }
  roomId = room_id;
};

export const sendMessagewithSocket = (message) => {
  console.log("send message socket", message, socket);
  socket.emit("message", message);
};

const requestContact = () => {
  socket.emit("request-contacts", userId);
};

const allContacts = () => {
  socket.on("all-contacts", (allContact) => {
    localStorage.setItem("contacts", allContact);
  });
};
