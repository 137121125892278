// src/axiosConfig.js (modified response interceptor)
import axios from 'axios';
import Swal from 'sweetalert2'; // Example: Using SweetAlert2 for notifications

const Instance = axios.create({
  baseURL: 'https://myhummingbird.net/index.php',
});

Instance.interceptors.request.use(
    (config) => {
      // Modify request config here (e.g., add authorization headers)
      const authToken = localStorage.getItem('authToken'); // Example: get token from localStorage
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (error) => {
      // Handle request error here
      return Promise.reject(error);
    }
  );

Instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/login';
    } else {
      Swal.fire('Error', error.response.data.message || 'An error occurred', 'error');
    }
    return Promise.reject(error);
  }
);

export default Instance
