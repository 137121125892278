import React, { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, IconButton, TextField } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { CSSTransition } from "react-transition-group";
import InputEmoji from "react-input-emoji";
import { useSelector, useDispatch } from "react-redux";
import { scheduleMessage } from "../Redux/Slice/ChatUserSlice";
// import { newDate } from "react-datepicker/dist/date_utils";
// import { setDate } from "date-fns";
// import { setTime } from "react-datepicker/dist/date_utils";


const ScheduleMessage = ({ isOpen, onClose, initialMessage }) => {
  const [repeat, setRepeat] = useState(null);
  const [repeatmsg, setRepeatMsg] = useState(0);
  const [forever, setForever] = useState(false);
  const [repeatCount, setRepeatCount] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [scheduledMessage, setScheduledMessage] = useState(initialMessage || "");
  const [messageType, setMessageType] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const sender_id = localStorage.getItem("m_sender_id");
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const { MessageDetails } = useSelector((state) => ({
    MessageDetails: state.user?.Messages,
  }));

  const handleRepeatChange = (type) => {
    setRepeat(repeat === type ? null : type);
    setRepeatMsg(
      type === "Daily"
        ? 1
        : type === "Weekly"
        ? 2
        : type === "Monthly"
        ? 3
        : type === "Yearly"
        ? 4
        : 0
    );
  };

  const handleForeverClick = () => {
    setForever((prev) => {
      setRepeatCount(prev ? 1 : 0); // Set repeatCount to 0 if forever is true, otherwise set to 1
      return !prev;
    });
  };

  const handleRepeatCountChange = (delta) => {
    if (!forever) {
      setRepeatCount((prevCount) => Math.max(0, prevCount + delta));
    }
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    if (
      date &&
      startTime &&
      date.toDateString() === new Date().toDateString()
    ) {
      setStartTime(new Date());
    }
  };

  const handleTimeChange = (time) => {
    setStartTime(time);
  };

  const now = new Date();
  const oneMinuteLater = new Date(now.getTime() + 60000); // 1 minute later
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const combineDateAndTime = (date, time) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    );
  };


  const handleFileChange = (e) => {
    let m_message_type;
    let m_media = e.target.files;
  
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type.startsWith("image/")) {
        m_message_type = "1";
      } else if (file.type.startsWith("audio/")) {
        m_message_type = "3";
      } else if (file.type.startsWith("video/")) {
        m_message_type = "4";
      } else if (file.type.startsWith("application/")) {
        m_message_type = "2";
      } else {
        // Handle unsupported file types
        console.log("Unsupported file type");
        return;
      }
      setMessageType(m_message_type);
      setSelectedFile(m_media);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const SendscheduledMessage = () => {
    if (!scheduledMessage.trim() && !selectedFile) {
      setErrorMessage("Message or image cannot be empty!");
      return;
    }
   var body = {
    m_room_id: MessageDetails?.room.r_id,
    m_message_type: messageType,
    m_message: scheduledMessage,
    m_sender_id: sender_id,
    m_is_scheduled: "1",
    m_scheduled_at: Math.floor(combineDateAndTime(startDate, startTime).getTime() / 1000),
    m_repeat_type: repeatmsg,
    m_repeat_count: forever ? -1 : repeatCount,
    m_media:selectedFile
   }
  
    dispatch(scheduleMessage(body));
    setSelectedFile(null);
    setPreviewUrl(null);
    setScheduledMessage("");
    setStartTime(null);
    onClose(); // Close the modal after scheduling
  
  };
  
 



 
  return (
    <CSSTransition in={isOpen} timeout={300} classNames="modal" unmountOnExit>
      <div>
      


{previewUrl && (
  <div className="image-preview" onClick={() => setPreviewUrl(null)}>
    <button className="close-preview-button" onClick={() => setPreviewUrl(null)}>
      &times;
    </button>
    {messageType === "1" && (
      <img
        className="image-preview-img img-fluid w-90 h-90 rounded-0"
        src={previewUrl}
        alt="Preview"
      />
    )}
    {messageType === "3" && (
      <audio controls className="image-preview-img img-fluid w-90 h-90 rounded-0">
        <source src={previewUrl} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    )}
    {messageType === "4" && (
      <video controls className="image-preview-img img-fluid w-90 h-90 rounded-0">
        <source src={previewUrl} type="video/mp4" />
        Your browser does not support the video element.
      </video>
    )}
    {messageType === "2" && (
    <div className="document-preview">
    <iframe
      src={previewUrl}
      className="document-preview-iframe"
      width="100%"
      height="500px"
    />
    </div>
    )}
    <div
      className="message-input-container"
      onClick={(e) => e.stopPropagation()}
    >
      <input
        type="text"
        className="message-input-field"
        placeholder="Type your message..."
        value={scheduledMessage}
        onChange={(e) => setScheduledMessage(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
      <button
        className="send-message-button"
        onClick={(e) => {
          e.stopPropagation();
          SendscheduledMessage();
        }}
      >
        Send
      </button>
    </div>
  </div>
)}

        <div style={overlayStyle}>
          <div style={modalStyle}>
            <button onClick={onClose} style={closeButtonStyle}>
              ×
            </button>
            <h5 className="fw-bold">Choose Date and Time for message</h5>
            <div>
              <p>Repeat message? (Optional)</p>
              <div style={repeatButtonContainerStyle}>
                {["Daily", "Weekly", "Monthly", "Yearly"].map((type) => (
                  <Button
                    key={type}
                    variant={repeat === type ? "contained" : "outlined"}
                    onClick={() => handleRepeatChange(type)}
                    className="fw-bold"
                  >
                    <i className="bi bi-stopwatch mr-2 fw-bold fs-5"></i> {type}
                  </Button>
                ))}
              </div>
            </div>
            <div>
              <p className="fw-bold fs-5">
                How many times you want to repeat message
              </p>
              <div style={repeatControlContainerStyle}>
                <Button
                  variant={forever ? "contained" : "outlined"}
                  onClick={handleForeverClick}
                  className="fw-bold bor"
                >
                  <i className="bi bi-envelope-check text-dark fw-bold mr-2"></i>{" "}
                  Forever
                </Button>
                <span>OR</span>
                <div className="ctrl">
                  <div
                    className="ctrl__button ctrl__button--decrement"
                    onClick={() => !forever && handleRepeatCountChange(-1)}
                    style={{ cursor: forever ? "not-allowed" : "pointer" }}
                  >
                    &ndash;
                  </div>
                  <div className="ctrl__counter">
                    <input
                      className="ctrl__counter-input"
                      maxLength="10"
                      type="text"
                      value={repeatCount}
                      onChange={(e) => {
                        if (!forever) {
                          setRepeatCount(
                            Math.max(0, parseInt(e.target.value, 10) || 0)
                          );
                        }
                      }}
                      disabled={forever}
                    />
                    <div className="ctrl__counter-num">
                      {forever ? "0" : repeatCount}
                    </div>
                  </div>
                  <div
                    className="ctrl__button ctrl__button--increment"
                    onClick={() => !forever && handleRepeatCountChange(1)}
                    style={{ cursor: forever ? "not-allowed" : "pointer" }}
                  >
                    +
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-between">
              <DatePicker
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="MMMM d, yyyy"
                customInput={<input className="form-control" />}
                minDate={today}
                placeholderText="Select Date..."
              />
              <DatePicker
                selected={startTime}
                onChange={handleTimeChange}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={1}
                timeCaption="Time"
                dateFormat="h:mm aa"
                customInput={<input className="form-control" />}
                minTime={
                  startDate && startDate.toDateString() === today.toDateString()
                    ? oneMinuteLater
                    : new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate(),
                        0,
                        0,
                        0
                      )
                }
                maxTime={
                  new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    23,
                    59,
                    0
                  )
                }
                placeholderText="Select Time..."
              />
            </div>
            <div className="position-relative input-container">
              <div className="d-flex align-items-end bg-white input-wrapper">
                <div className="p-4 d-flex">
                  <label
                    id="inputButton"
                    className="custom-file-upload btn btn-icon btn-light d-inline-block btn-md btn-pill btn-circle"
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-plus-lg"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                      ></path>
                    </svg>
                  </label>
                </div>
                <div className="p-3 w-100">
                  <div
                    className="flex-grow-1 d-flex mb-2"
                    style={{ color: "yellow" }}
                  >
                    <InputEmoji
                      cleanOnEnter
                      placeholder="Type a message..."
                      value={scheduledMessage}
                      onChange={setScheduledMessage}
                      onEnter={SendscheduledMessage}
                      required
                    />
                    <div className="p-2 d-flex" onClick={SendscheduledMessage}>
                      <img
                        style={{ marginTop: "4px", height: "30px" }}
                        src="https://cdn-icons-png.flaticon.com/128/4024/4024582.png"
                        alt="Send"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};


const errorMessageStyle = {
  color: "red",
  marginTop: "10px",
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2000,
};

const modalStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "700px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  position: "relative",
  animation: "fadeIn 0.3s",
};

const closeButtonStyle = {
  top: "10px",
  right: "10px",
  background: "none",
  border: "none",
  fontSize: "25px",
  cursor: "pointer",
  color: "red",
  fontWeight: "bold",
  position: "absolute",
};

const repeatButtonContainerStyle = {
  display: "flex",
  marginBottom: "10px",
  justifyContent: "space-between",
};

const repeatControlContainerStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const numberInputStyle = {
  width: "50px",
  textAlign: "center",
  MozAppearance: "textfield",
  WebkitAppearance: "none",
  appearance: "textfield",
};

const errorStyle = {
  color: "red",
};
export default ScheduleMessage;
