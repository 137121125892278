import React, { useState, useEffect, useCallback, useRef } from "react";
import "../../Styles/newpage.css";
import logo from "../../assets/Images/logo.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserProfileEdit from "../../Pages/UserProfileEdit";
import { useSelector, useDispatch } from "react-redux";
import { json, useNavigate } from "react-router-dom";
import share from "../../assets/Images/share.png";
import { hummingContacts } from "../../Redux/Slice/ChatUserSlice";
import { deleteRoom } from "../../Redux/Slice/ChatUserSlice";
import Swal from "sweetalert2";
import { allScheduledMessage } from "../../Redux/Slice/ChatUserSlice";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import { deleteReciverMessages } from "../../Redux/Slice/ChatUserSlice";
import defalutImage from "../../assets/Images/defaultImage.png";
import {
  Room,
  Userprofile,
  Groups,
  OnlineUserList,
  offlineUser,
} from "../../Redux/Slice/ChatUserSlice";
import Loading from "../../Component/Loading/Loading";
import { getSocket } from "../../Config/Socket/socket";
import { initializeSocket } from "../../Config/Socket/socket";
import { Slide, toast } from "react-toastify";
import SettingNav from "../../Component/SettingNav";

const Sidebar2 = ({ setshowChat, isNavVisible, setNavVisible }) => {
  // Buttons indicater
  const [activeItem, setActiveItem] = useState("Chat");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [roomId, setRoomId] = useState([]);
  const [unseenMessages, setUnseenMessages] = useState({});
  const [allContacts, setAllContacts] = useState([]);
  const [allNumbers, setAllNumbers] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [hummingAllContacts, setHummingAllContacts] = useState([]);
  const [hummingContactsDetails, setHummingAllContactsDetails] = useState([]);
  const [deleteRoomId, setDeleteRoomId] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredHummingContacts, setFilteredHummingContacts] = useState([]);
  const [filteredAllContacts, setFilteredAllContacts] = useState([]);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [activeTab, setActiveTab] = useState("chat");
  const [showForm, setShowForm] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [activeRoom, setActiveRoom] = useState(null);
  const [AllScheduledMessage, setAllScheduledMessage] = useState([]);
  const [messagesByDate, setMessagesByDate] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);


  const {
    RoomsData,
    loading,
    profile,
    error,
    Group,
    userProfileOnline,
    MessageDetails,
    allHummingContacts,
    AllScheduleMessages,
    Messages,
  } = useSelector((state) => {
    return {
      RoomsData: state?.user?.rooms,
      Group: state.user?.Groups,
      profile: state.user.Userprofile?.user,
      userProfileOnline: state.user.userOnline,
      loading: state?.user?.isLoading,
      response: state.user?.response,
      error: state?.user?.error,
      MessageDetails: state.user?.Messages?.room?.r_id,
      allHummingContacts: state.user?.hummingContacts,
      AllScheduleMessages: state.user?.AllScheduledMessage?.data,
      Messages: state.user.Messages?.room?.messages,
    };
  });

  // All Schedual MEssages
  useEffect(() => {
    setAllScheduledMessage(AllScheduleMessages);
  }, [AllScheduleMessages]);

  const indicatorRef = useRef(null);
  const navRef = useRef(null);

  const handleIndicator = (el) => {
    const indicator = indicatorRef.current;
    if (indicator) {
      indicator.style.width = `${el.offsetWidth}px`;
      indicator.style.left = `${el.offsetLeft}px`;
      indicator.style.backgroundColor = el.getAttribute("#201634");
    }
  };

  useEffect(() => {
    const navItems = navRef.current.querySelectorAll(".nav-item");
    const activeEl = Array.from(navItems)?.find((item) =>
      item.classList.contains("is-active")
    );
    if (activeEl) {
      handleIndicator(activeEl);
    }
  }, [activeItem]);

  const handleClick = (e) => {
    setActiveItem(e.target.textContent);
    if (e.target.textContent === "Schedules") {
      dispatch(allScheduledMessage());
    }
  };

  //  buttons indicater end

  // End Show Online user

  const keys = [];
  initializeSocket();

  for (let key in unseenMessages) {
    keys.push(key);
  }

  let socket = getSocket();

  const User_id = localStorage.getItem("m_sender_id");

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  if (isChecked === true) {
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    width: "50%",
  };

  var details = MessageDetails;
  const BASE_URL = "https://myhummingbird.net";
  const imageUrl = `${BASE_URL}${profile?.user_image}`;

  useEffect(() => {
    if (allHummingContacts?.data?.length > 0) {
      setHummingAllContacts(allHummingContacts?.data);
    }
  }, []);

  useEffect(() => {
    if (hummingAllContacts) {
      dispatch(hummingContacts(hummingAllContacts));
    }
  }, []);

  useEffect(() => {
    const handleNewMessage = (room_id) => {
      if (details !== room_id) {
        setRoomId((prevRoomId) => [...prevRoomId, room_id]);
        setUnseenMessages((prevUnseenMessages) => ({
          ...prevUnseenMessages,
          [room_id]: (prevUnseenMessages[room_id] || 0) + 1,
        }));
      }
    };

    socket.on("new-message", handleNewMessage);

    return () => {
      socket.off("new-message", handleNewMessage);
    };
  }, [details]);

  const updateAllNumbers = useCallback((contacts) => {
    const numbers = contacts?.map((contact) => contact.number) || [];
    setAllNumbers((prevNumbers) => {
      // Only update if numbers have changed
      if (JSON.stringify(prevNumbers) !== JSON.stringify(numbers)) {
        return numbers;
      }
      return prevNumbers;
    });
  }, []);

  useEffect(() => {
    // Retrieve data from localStorage
    let userContacts = localStorage.getItem("contacts");

    try {
      // Parse if userContacts is a JSON string, otherwise assign directly
      const parsedData = userContacts ? JSON.parse(userContacts) : [];

      // Ensure parsed data is an array
      if (Array.isArray(parsedData)) {
        // Update state only if data has changed
        setAllContacts((prevContacts) => {
          if (JSON.stringify(prevContacts) !== JSON.stringify(parsedData)) {
            updateAllNumbers(parsedData);
            return parsedData;
          }
          return prevContacts;
        });
      } else {
        console.error("Parsed data is not an array", parsedData);
      }
    } catch (error) {
      console.error("Failed to parse data", error);
    }
  }, [updateAllNumbers]);

  useEffect(() => {
    setJsonData(JSON.stringify(allNumbers));
    if (jsonData) {
      dispatch(hummingContacts(jsonData));
    }
  }, [jsonData]);

  // Effect to reset unseen messages count for the current room
  useEffect(() => {
    if (details) {
      setUnseenMessages((prevUnseenMessages) => ({
        ...prevUnseenMessages,
        [details]: 0,
      }));
    }
  }, [details]);

  //hummingContactsDetails
  useEffect(() => {
    const matchedContacts = allContacts
      ?.map((contact) => {
        const match = hummingAllContacts?.find(
          (hummingContact) => hummingContact.user_mobile_num === contact.number
        );

        return match
          ? {
              user_first_name: contact.name,
              user_image: match.user_image,
              user_mobile_num: match.user_mobile_num,
            }
          : null;
      })
      .filter((contact) => contact !== null);

    localStorage.setItem(
      "hummingContactsDetails",
      JSON.stringify(matchedContacts)
    );
  }, [allContacts,hummingAllContacts]);

  // store Local data base
  useEffect(() => {
    // Retrieve data from localStorage
    let userContacts = localStorage.getItem("contacts");

    try {
      // Parse if userContacts is a JSON string, otherwise assign directly
      const parsedData = userContacts ? JSON.parse(userContacts) : [];

      // Ensure parsed data is an array
      if (Array.isArray(parsedData)) {
        // Update state only if data has changed
        setAllContacts((prevContacts) => {
          if (JSON.stringify(prevContacts) !== JSON.stringify(parsedData)) {
            updateAllNumbers(parsedData);
            return parsedData;
          }
          return prevContacts;
        });
      } else {
        console.error("Parsed data is not an array", parsedData);
      }
    } catch (error) {
      console.error("Failed to parse data", error);
    }
  }, [updateAllNumbers]);

  useEffect(() => {
    const matchedContacts = allContacts
      ?.map((contact) => {
        const match = hummingAllContacts?.find(
          (hummingContact) =>
            hummingContact?.user_mobile_num === contact?.number
        );

        return match
          ? {
              user_id: match?.user_id,
              user_first_name: contact?.name,
              user_image: match?.user_image,
              user_mobile_num: match?.user_mobile_num,
            }
          : null;
      })
      .filter((contact) => contact !== null);

    // Log the matchedContacts array to check its content

    // Update state

    // Only store in localStorage if there are matched contacts
    if (matchedContacts?.length > 0) {
      localStorage.setItem("matchedContacts", JSON.stringify(matchedContacts));
    } else {
      console.warn("No matched contacts to store in localStorage");
    }
  }, [allContacts, hummingAllContacts]);

  useEffect(() => {
    const handleTyping = (data) => {
      setIsTyping(data.roomId);
    };

    const handleNotTyping = () => {
      setIsTyping(false);
    };

    // Attach event listeners
    socket.on("on-typing", handleTyping);
    socket.on("on-not-typing", handleNotTyping);

    // Cleanup on component unmount
    return () => {
      socket.off("on-typing", handleTyping);
      socket.off("on-not-typing", handleNotTyping);
    };
  }, []);

  useEffect(() => {
    try {
      const storedMatchedContacts =
        JSON.parse(localStorage.getItem("matchedContacts")) || [];
      if (storedMatchedContacts?.length > 0) {
        setHummingAllContactsDetails(storedMatchedContacts);
      }
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
    }
  }, [allContacts , hummingAllContacts]);

  // Function to manually reset unseen messages count for a specific room
  const resetUnseenMessages = (room_id) => {
    setUnseenMessages((prevUnseenMessages) => ({
      ...prevUnseenMessages,
      [room_id]: 0,
    }));
  };

  useEffect(() => {
    if (User_id) {
      dispatch(Userprofile(User_id));
    }
    dispatch(Groups());
    dispatch(Room());
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("online", (userId) => {
        dispatch(OnlineUserList(userId));
      });
    }

    // Clean up the socket listener when the component is unmounted
  }, []);

  useEffect(() => {
    socket.on("offline", (userId) => {
      dispatch(offlineUser(userId));
    });
  }, []);

  const deleteRooms = (RoomId) => {
    setDeleteRoomId((prevSelectedMessageId) => {
      // Toggle selection: if present, remove it; if not, add it
      if (prevSelectedMessageId.includes(RoomId)) {
        return prevSelectedMessageId.filter((id) => id !== RoomId);
      } else {
        return [RoomId];
      }
    });
  };

  const handledeleteroom = (RoomId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "It will be permanently deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteRoom(RoomId));
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        // Perform the actual deletion action here
      }
    });
  };

  // search query
  useEffect(() => {
    // Filter humming contacts based on search query
    const filteredHumming = hummingContactsDetails.filter(
      (contact) =>
        contact.user_first_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        contact.user_mobile_num.includes(searchQuery) // Allow searching by name or number
    );
    setFilteredHummingContacts(filteredHumming);

    // Filter all contacts excluding those in hummingContactsDetails and based on search query
    const filteredAll = allContacts.filter(
      (contact) =>
        !hummingContactsDetails.some(
          (hummingContact) => hummingContact.user_mobile_num === contact.number
        ) &&
        (contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          contact.number.includes(searchQuery)) // Allow searching by name or number
    );
    setFilteredAllContacts(filteredAll);
  }, [searchQuery, hummingContactsDetails, allContacts]);

  // tap to go chat
  const handleShareClick = (contactDetail) => {
    const User_id = localStorage.getItem("m_sender_id");

    // Find the room where both User_id and contactDetail.user_id are present in r_users
    const room = RoomsData?.rooms?.find((RecentUsers) => {
      const decodeR_user = JSON.parse(RecentUsers?.r_users);
      return (
        decodeR_user.includes(contactDetail?.user_id.toString()) &&
        decodeR_user.includes(User_id)
      );
    });

    if (room) {
      const image = `${BASE_URL}${contactDetail?.user_image}`;

      setChatRoomId(room.r_id); // Set chatRoomId to the room's r_id
      setshowChat({
        roomType: "chat", // Adjust this based on your needs
        room_id: room.r_id,
        UserId: contactDetail?.user_id,
        UserName: contactDetail?.user_first_name,
        userNumber: contactDetail?.user_mobile_num,
        userImage: image,
      });
    } else {
      console.log("No matching room found.");
    }
  };

  //tap to go chat end

  const handleTabChange = (event) => {
    setActiveTab(event.target.value);
  };
  // styling  scduled message
  const handleButtonClick = () => {
    setShowForm(!showForm);
  };

  // Dispatch all Scdualed Messages

  // Search Funtiolity

  // show image fu

  // All Schedual funtionlity

  useEffect(() => {
    if (AllScheduledMessage?.length > 0) {
      const groupedMessages = AllScheduledMessage?.reduce((acc, msg) => {
        const date = msg.m_created_at.split(" ")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(msg);
        return acc;
      }, {});

      setMessagesByDate(groupedMessages);
    }
  }, [AllScheduledMessage]);

  const dates = Object.keys(messagesByDate);

  const deleteSchedualMsg = (m_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You cancel schedule message!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const messageList = Array.isArray(m_id) ? m_id : [m_id];
        const encodedMessageList = JSON.stringify(messageList);
        dispatch(
          deleteReciverMessages({
            messageList: encodedMessageList,
            delete_for: "1",
          })
        );
        Swal.fire({
          title: "Canceled!",
          text: "Your schedule message has been canceled.",
          icon: "success",
        });
      }
    });
  };

  return (
    <div>
      <div className="chat-app">
        <header className="chat-header d-flex align-items-center justify-content-between">
          <img
            style={{ width: "180px" }}
            src={logo}
            alt="Logo"
            className="logo d-flex align-items-center justify-content-between"
          />
        </header>

        <div className="d-flex align-items-center justify-content-between mt-2 p-2">
          <h2 className="chat-title fw-bold fs-3">Chat</h2>
          <button
            className="btn   border-0 bg-transparent"
            onClick={() => setNavVisible(!isNavVisible)}
          >
            <i className="fas fa-ellipsis-v  text-dark fw-bold fs-4"></i>
          </button>
        </div>

        <div className="d-flex align-items-center justify-content-between mt-2 p-2">
          <span className=" p-3" data-toggle="modal" data-target="#contactList">
            <i class="fa fa-user-plus fs-4  "></i>
          </span>
          <div className="search-container">
            <span className="search-icon fa fa-search"></span>{" "}
            {/* Replace with your icon */}
            <input
              type="text"
              className="search-input"
              placeholder="Search..."
            />
          </div>
        </div>

        {/* contact modal */}
        <div
          class="modal fade"
          id="contactList"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content" style={{ backgroundColor: "#fefefa" }}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <i className="fa fa-user-plus"></i> Contact List
                </h5>
                <button
                  type="button"
                  class="close mb-3"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {/* <span aria-hidden="true">&times;</span> */}
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group has-search-right">
                  <a href="#" className="form-control-feedback mt-2">
                    <span className="fa fa-search"></span>
                  </a>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Contact"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div>
                  <h6>Contact on humming</h6>
                  {filteredHummingContacts?.map((contact, index) => {
                    const imageUrl = `${BASE_URL}/${contact.user_image}`;

                    return (
                      <ul className="contact-list" key={index}>
                        <li>
                          <img src={imageUrl} alt={contact.user_first_name} />
                          <div className="contact-info">
                            <h3 className="heading--name">
                              {contact.user_first_name}
                            </h3>
                            <div className="contact-item">
                              {contact.user_mobile_num}
                            </div>
                          </div>
                          <img
                            src={share}
                            alt="share"
                            style={{ height: "30px" }}
                            onClick={() => handleShareClick(contact)}
                          />
                        </li>
                      </ul>
                    );
                  })}
                </div>
                <hr />
                <h6>Invite Contacts</h6>
                <div>
                  {filteredAllContacts?.length > 0 ? (
                    filteredAllContacts?.map((contact, index) => (
                      <ul className="contact-list" key={index}>
                        <li>
                          <img
                            src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                            alt="contact"
                          />
                          <div className="contact-info">
                            <h3 className="heading--name">{contact.name}</h3>
                            <div className="contact-item">{contact.number}</div>
                          </div>
                          <button className="btn btn-primary rounded">
                            Invite
                          </button>
                        </li>
                      </ul>
                    ))
                  ) : (
                    <p>No contacts found.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* contatct modal end */}

        {/* user Online */}
        <div className="container mb-0">
          <div className="container d-flex gap-2">
            {userProfileOnline?.map((u_id, index) => {
              const contactDetail = hummingContactsDetails?.find(
                (data) => data?.user_id === u_id
              );

              const BASE_URL = "https://myhummingbird.net";
              const imageUrl = `${BASE_URL}${contactDetail?.user_image}`;

              // Convert room user strings to arrays
              const parsedRooms = RoomsData?.rooms?.map((room) => ({
                ...room,
                r_users: JSON.parse(room.r_users),
              }));

              // Filter rooms to include only those with the specific User_id
              const userRooms = parsedRooms?.filter((room) =>
                room.r_users?.includes(User_id)
              );

              const userRoom = userRooms?.find((room) =>
                room.r_users?.includes(u_id)
              );
            
              const test = () => {
                if (userRoom && contactDetail) {
                  setshowChat({
                    roomType: userRoom?.r_type,
                    room_id: userRoom?.r_id,
                    UserId: contactDetail?.user_id,
                    UserName: contactDetail?.user_first_name,
                    userNumber: contactDetail?.user_mobile_num,
                    userImage: imageUrl,
                  });
                }
              };

              if (contactDetail) {
                return (
                  <h1>
                    {" "}
                    <div className="story-section mb-2">
                      <div className="story">
                        <button
                          type="button"
                          className="icon-button "
                          style={{ marginRight: "10px" }}
                        >
                          <img
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 25,
                              border: "2px solid #ebdcef",
                            }}
                            className="material-icons"
                            src={imageUrl}
                            onClick={test}
                          />
                          <span className="icon-button__badge" />
                        </button>

                        <span className=" mt-0">
                          {contactDetail?.user_first_name}
                        </span>
                      </div>
                    </div>
                  </h1>
                );
              }
              return null;
            })}
          </div>
        </div>
        {/* User Online end */}
        <nav className="nav" ref={navRef}>
          <a
            className={`nav-item ${activeItem === "Chat" ? "is-active" : ""}`}
            active-color="orange"
            onClick={handleClick}
          >
            Chat
          </a>
          <a
            className={`nav-item ${activeItem === "Groups" ? "is-active" : ""}`}
            active-color="green"
            onClick={handleClick}
          >
            Groups
          </a>
          <a
            className={`nav-item ${
              activeItem === "Schedules" ? "is-active" : ""
            }`}
            active-color="blue"
            onClick={handleClick}
          >
            Schedules
          </a>
          {/* <span className="nav-indicator" ref={indicatorRef}></span> */}
        </nav>

        {/* All Rooms and Groups  Schedual */}
        <div className="chat-list rounded-start-3 mt-3">
          {activeItem === "Chat" ? (
            <>
              {RoomsData ? (
                RoomsData?.rooms?.length === 0 ? (
                  <p>No rooms available</p>
                ) : (
                  RoomsData?.rooms?.map((RecentUsers, index) => {
                    const date = new Date(
                      RecentUsers?.r_last_message?.m_created_at
                    );
                    var RecentUSerId = JSON.parse(RecentUsers.r_users);
                    var filteredRecentUserId = RecentUSerId.filter(
                      (id) => id !== User_id
                    );
                    var recentUserID = filteredRecentUserId[0];

                    const contactDetail = hummingContactsDetails?.find(
                      (data) => data?.user_id === recentUserID
                    );

                    const BASE_URL = "https://myhummingbird.net";
                    const imageUrl = `${BASE_URL}${contactDetail?.user_image}`;
                    return (
                      <>
                        <div
                          key={index}
                          className={`chat-item d-flex align-items-center mt-2 ${
                            activeRoom === RecentUsers.r_id ? "active" : ""
                          }`}
                          onDoubleClick={() => {
                            deleteRooms(RecentUsers.r_id);
                          }}
                          onClick={() => {
                            setActiveRoom(RecentUsers.r_id);
                            setshowChat({
                              roomType: RecentUsers?.r_type,
                              room_id: RecentUsers?.r_id,
                              UserId: contactDetail?.user_id,
                              UserName: contactDetail?.user_first_name,
                              userNumber: contactDetail?.user_mobile_num,
                              userImage: imageUrl,
                            });
                          }}
                          style={{ borderLeft: "7px solid blue" }}
                        >
                          <img
                            src={imageUrl}
                            alt="User"
                            className="avatar"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defalutImage;
                            }}
                          />

                          <div className="chat-info">
                            <h5 className="chat-name overflow-hidden">
                              {contactDetail?.user_first_name ||
                                RecentUsers?.r_admin_number}
                            </h5>
                            <p className="chat-preview">
                              {isTyping === RecentUsers.r_id ? (
                                <>
                                  <p
                                    style={{
                                      color: "green",
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Typing...
                                  </p>
                                </>
                              ) : (
                                RecentUsers.r_last_message?.m_message
                              )}
                            </p>
                          </div>
                          {unseenMessages[RecentUsers.r_id] > 0 && (
                            <span className="icon-button__badges p-2">
                              {unseenMessages[RecentUsers.r_id]}
                            </span>
                          )}
                          {deleteRoomId.includes(
                            RecentUsers.r_id.toString()
                          ) && (
                            <button
                              className="btn text delete-button"
                              style={{
                                marginTop: "0px",
                                height: "37px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "transparent",
                                color: "red",
                              }}
                              onClick={() => handledeleteroom(RecentUsers.r_id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                          <button className="btn btn-outline-primary">
                            <i className="fas fa-paper-plane"></i>
                          </button>
                        </div>
                      </>
                    );
                  })
                )
              ) : (
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </>
          ) : null}

          {activeItem === "Groups" ? (
            <>
              {Group ? (
                Group?.rooms?.length === 0 ? (
                  <p>No groups available</p>
                ) : (
                  Group?.rooms?.map((RecentGroup) => {
  
                    const date = new Date(
                      RecentGroup.r_last_message?.m_created_at
                    );
                    const BASE_URL = "https://myhummingbird.net";
                    const imageUrl = RecentGroup?.r_avatar
                      ? `${BASE_URL}${RecentGroup?.r_avatar}`
                      : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7ubfHFSz6rfbRrJgzL-7LXwN4FstLhLUqxZb5R_8BKv2u3Mde_GWjez5ZfSl5LkpmaKA&usqp=CAU";

                    return (
                      <>
                        <div
                          className={`chat-item d-flex align-items-center mt-2 ${
                            activeRoom === RecentGroup.r_id ? "active" : ""
                          }`}
                          onDoubleClick={() => {
                            deleteRooms(RecentGroup.r_id);
                          }}
                          onClick={() => {
                            setActiveRoom(RecentGroup.r_id);
                            setshowChat({
                              roomType: RecentGroup?.r_type,
                              room_id: RecentGroup?.r_id,
                              UserName: RecentGroup?.r_name,
                              userImage: imageUrl,
                            });
                          }}
                        >
                          <img src={imageUrl} alt="User" className="avatar" />
                          <div className="chat-info">
                            <h5 className="chat-name overflow-hidden">
                              {RecentGroup.r_name}
                            </h5>
                            <p className="chat-preview">
                              {isTyping === RecentGroup.r_id ? (
                                <>
                                  <p
                                    style={{
                                      color: "green",
                                      fontStyle: "italic",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Typing...
                                  </p>
                                </>
                              ) : (
                                RecentGroup.r_last_message?.m_message
                              )}
                            </p>
                          </div>
                          {unseenMessages[RecentGroup.r_id] > 0 && (
                            <span className="icon-button__badges p-2">
                              {unseenMessages[RecentGroup.r_id]}
                            </span>
                          )}
                          {deleteRoomId.includes(
                            RecentGroup.r_id.toString()
                          ) && (
                            <button
                              className="btn text delete-button"
                              style={{
                                marginTop: "0px",
                                height: "37px",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "transparent",
                                color: "red",
                              }}
                              onClick={() => handledeleteroom(RecentGroup.r_id)}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                          <button className="btn btn-outline-primary">
                            <i className="fas fa-paper-plane"></i>
                          </button>
                        </div>
                      </>
                    );
                  })
                )
              ) : (
                <div class="spinner-border text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              )}
            </>
          ) : null}

          {activeItem === "Schedules" ? (
            <>
              <div className="container  mb-5">
                <div className="date-picker-container my-4">
                  <div className="date-picker d-flex overflow-auto">
                    {dates.map((date) => (
                      <Button
                        class="bg-light text-dark fw-bolder p-3  border-0 rounded-3"
                        key={date}
                        variant={
                          selectedDate === date
                            ? "primary"
                            : "outline-secondary"
                        }
                        onClick={() => setSelectedDate(date)}
                      >
                        {new Date(date).toLocaleDateString("en-US", {
                          month: "short",
                          day: "numeric",
                          weekday: "short",
                        })}
                      </Button>
                    ))}
                  </div>
                </div>
                {selectedDate && (
                  <div>
                    <h5 className="text-center">
                      You have {messagesByDate[selectedDate].length} scheduled
                      messages for this date.
                    </h5>
                    <ListGroup className=" mb-5">
                      {messagesByDate[selectedDate].map((msg) => (
                        <Card key={msg.m_id} className="my-2">
                          <Card.Body>
                            <Card.Title>
                              {" "}
                              Sent to: {msg.m_receiver_name}
                            </Card.Title>
                            <ListGroup variant="flush">
                              <ListGroup.Item>{msg.m_message}</ListGroup.Item>
                              <ListGroup.Item>
                                At:{" "}
                                {new Date(
                                  msg.m_scheduled_at * 1000
                                ).toLocaleTimeString("en-US")}
                              </ListGroup.Item>
                            </ListGroup>
                            <Button
                              variant="danger"
                              className="mt-2"
                              onClick={() => deleteSchedualMsg(msg?.m_id)}
                            >
                              Cancel
                            </Button>
                          </Card.Body>
                        </Card>
                      ))}
                    </ListGroup>
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Sidebar2;
