import React, { useState } from "react";
import Sidebar from "./SideBar/Sidebar";
import Chat from "./Chat/Chat";
import "../Styles/DashBoard.scss";
import DefaultPage from "../Pages/DefaultPage";
import Chat1 from "./Chat/Chat1";
import Sidebar2 from "./SideBar/Sidebar2";
import ProfileSection from "./ProfileSection/ProfileSection";
const Dashboard = () => {
  const [showChat, setshowChat] = useState("");
  const [isNavVisible, setNavVisible] = useState(false);
  const [showClientInfo, setShowClientInfo] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [deleteMyAccount, setDeleteMyAccount] = useState(false);
  const [showEditProfile, setShowEditProfile] = useState(false);

  return (
    <div className="home">
      <div className="containerd">
        <Sidebar2
          setshowChat={setshowChat}
          isNavVisible={isNavVisible}
          setNavVisible={setNavVisible}
        />

        {showChat === "" ? (
          <DefaultPage />
        ) : (
          <Chat1
            showChat={showChat}
            isNavVisible={isNavVisible}
            setShowClientInfo={setShowClientInfo}
            setClientInfo={setClientInfo}
            setNavVisible={setNavVisible}
            setDeleteMyAccount={setDeleteMyAccount}
            deleteMyAccount={deleteMyAccount}
            setShowEditProfile={setShowEditProfile}
            showEditProfile={showEditProfile}
          />
        )}
        <ProfileSection
          showClientInfo={showClientInfo}
          clientInfo={clientInfo} // Pass the client information to ProfileSection
          setShowClientInfo={setShowClientInfo}
          setClientInfo={setClientInfo}
          setDeleteMyAccount={setDeleteMyAccount}
          deleteMyAccount={deleteMyAccount}
          setShowEditProfile={setShowEditProfile}
          showEditProfile={showEditProfile}
        />
      </div>
    </div>
  );
};

export default Dashboard;
