import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addMessages, clearMessages } from "../../Config/IndexDB";
import {
  getSocket,
  initializeSocket,
  sendMessagewithSocket,
  userTyping,
} from "../../Config/Socket/socket";
import { joinRoom } from "../../Config/Socket/socket";
import axios, { all } from "axios";
import Instance from "../../Config/Instance";

import { VerticalAlignBottom } from "@mui/icons-material";

const initialState = {
  otpVerficationStatus: [],
  rooms: null,
  Groups: null,
  sendMessageResponse: null,
  scheduleMessage: null,
  Messages: null, //first time all messages fetch from api
  isLoading: false,
  error: null,
  response: null,
  userDetails: null,
  userOnline: [],
  userTyping: [],
  hummingContacts: [],
  groupUserDetails: [],
  AllScheduledMessage: [],
  Aimessage: [],
  messageSeenCheck: [],
  userProfileUpdate: [],
  DeleteAccountResponce: [],
  DeletedAccountResponce: [],
  addMember: [],
};

//login User
export const loginEmploye = createAsyncThunk("loginEmploye", async (body) => {
  const Formdata = new FormData();

  Formdata.append("user_mobile_num", body.numbers);

  try {
    const response = await Instance.post(
      "https://myhummingbird.net/index.php/user/register-number",
      Formdata
    );

    return response.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// otp Verifiy
export const otpVerfication = createAsyncThunk(
  "otpVerfication",
  async (otp) => {
    const Formdata = new FormData();
    const otpInt = parseInt(otp, 10);
    Formdata.append("otp", otpInt);
    try {
      const response = await Instance.post(
        "https://myhummingbird.net/index.php/user/otp-verify",
        Formdata
      );

      return response.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

// genrate web fcm token
export const Fcmtoken = createAsyncThunk("Fcmtoken", async (Fcmtoken) => {
  const Formdata = new FormData();

  Formdata.append("web_fcm_token", Fcmtoken);

  try {
    const Fcmtoken = await Instance.post(
      "https://myhummingbird.net/index.php/user/update-user-profile",
      Formdata
    );

    return Fcmtoken.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// Fetch all Rooms

export const Room = createAsyncThunk("Room", async (body) => {
  try {
    const room = await Instance.get(
      "https://myhummingbird.net/index.php/room/fetch-rooms?room_type=0"
    );

    return room.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// fetch humming contact
export const hummingContacts = createAsyncThunk(
  "hummingContacts",
  async (body) => {
    const Formdata = new FormData();
    Formdata.append("contacts", body);

    try {
      const hummingContacts = await Instance.post(
        "https://myhummingbird.net/index.php/user/get-users",
        Formdata
      );

      return hummingContacts.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

// Fetch all Groups
export const Groups = createAsyncThunk("Groups", async (body) => {
  try {
    const room = await Instance.get(
      "https://myhummingbird.net/index.php/room/fetch-rooms?room_type=1"
    );

    return room.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// feth all Messages api
export const Messages = createAsyncThunk("Messages", async (room_id) => {
  try {
    const fetchMessages = await Instance.get(
      `https://myhummingbird.net/index.php/message/fetch-messages?room_id=${room_id}`
    );

    await addMessages(fetchMessages.data);
    joinRoom(room_id);
    return fetchMessages.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// Send Message Api
export const sendMessage = createAsyncThunk("sendMessage", async (body) => {
  const Formdata = new FormData();
  Formdata.append("room_id", body.m_room_id);
  Formdata.append("message_type", body.m_message_type);
  Formdata.append("message", body.m_message);
  Formdata.append("sender_id", body.m_sender_id);
  Formdata.append("is_scheduled", body.m_is_scheduled);
  Formdata.append("m_replied_on", body.m_replied_on);
  if (body.m_message_type === "1")
    Formdata.append("media_file", body.m_media[0]);

  try {
    const sendMessages = await Instance.post(
      "https://myhummingbird.net/index.php/message/send-message",
      Formdata
    );

    sendMessagewithSocket(sendMessages?.data?.msg);
    return sendMessages.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// get profile details
export const Userprofile = createAsyncThunk("Userprofile", async (user_id) => {
  try {
    const Userprofile = await Instance.get(
      `https://myhummingbird.net/index.php/user/get-single-user?user_id=${user_id}`
    );
    return Userprofile.data;
  } catch (error) {
    console.log("error", error);
    return error;
  }
});

// send Schedule Message
export const scheduleMessage = createAsyncThunk(
  "scheduleMessage",
  async (body, { rejectWithValue }) => {
    // Prepare FormData
    const formData = new FormData();
    formData.append("room_id", body.m_room_id);
    formData.append("message_type", body.m_message_type);
    formData.append("message", body.m_message);
    formData.append("sender_id", body.m_sender_id);
    formData.append("is_scheduled", body.m_is_scheduled);
    formData.append("scheduled_at", body.m_scheduled_at);
    formData.append("m_interval", body.m_repeat_type);
    formData.append("m_count", body.m_repeat_count);

    // Check and append media file if message type is '1'
    if (body.m_message_type !== "0" && body.m_media && body.m_media[0]) {
      formData.append("media_file", body.m_media[0]);
    }

    // Logging FormData for debugging purposes

    try {
      const response = await Instance.post(
        "https://myhummingbird.net/index.php/message/send-message",
        formData
      );
      sendMessagewithSocket(response.data.msg); // Assuming this is a function defined elsewhere
      return response.data;
    } catch (error) {
      console.error("Error sending message:", error);
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);
// check message seen api
export const messageSeenCheck = createAsyncThunk(
  "messageSeenCheck",
  async (body) => {
    const Formdata = new FormData();
    Formdata.append("m_id", body.m_id);
    Formdata.append("message_status", body.message_status);

    try {
      const messageSeenCheck = await Instance.post(
        "https://myhummingbird.net/index.php/message/update-message",
        Formdata
      );

      return messageSeenCheck.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

//get User group Details
export const groupUserDetails = createAsyncThunk(
  "groupUserDetails",
  async (room_id) => {
    try {
      const groupUserDetails = await Instance.get(
        `https://myhummingbird.net/index.php/room/get-group-info?room_id=${room_id}`
      );

      return groupUserDetails.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

// Clear Chat
export const ClearChats = createAsyncThunk(
  "ClearChat",
  async (MessageIDList, { rejectWithValue }) => {
    try {
      const clearChats = await Instance.get(
        `https://myhummingbird.net/index.php/message/delete-message`,
        {
          params: { message_id: MessageIDList, delete_for: "0" },
        }
      );

      return clearChats.data;
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// deleteReciverMessage Message

export const deleteReciverMessages = createAsyncThunk(
  "deleteReciverMessage",
  async (MessageID, { rejectWithValue }) => {
    try {
      const deleteReciverMessage = await Instance.get(
        `https://myhummingbird.net/index.php/message/delete-message`,
        {
          params: {
            message_id: MessageID.messageList,
            delete_for: MessageID.delete_for,
          },
        }
      );

      return deleteReciverMessage.data;
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// delete paticular Room
export const deleteRoom = createAsyncThunk("deleteRoom", async (r_id) => {
  try {
    const response = await Instance.get(
      `https://myhummingbird.net/index.php/room/delete-room?room_id=${r_id}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

// Impliment All scheduled Message api
export const allScheduledMessage = createAsyncThunk(
  "allScheduledMessage",
  async (r_id) => {
    try {
      const allScheduledMessage = await Instance.get(
        "https://myhummingbird.net/index.php/message/get-scheduled-messages"
      );
      return allScheduledMessage.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Impliment Schedual message api

export const aiMessage = createAsyncThunk(
  "aiMessage",
  async (ai_Message, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append(
      "prompt",
      `Generate a text message - ${ai_Message} Don't include any comments or suggestions, message should be impressive and ready to send`
    );

    try {
      const response = await Instance.post(
        "https://myhummingbird.net/index.php/generative-ai/get-ai-message",
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Impliment User profile update

export const UserProfieUpdate = createAsyncThunk(
  "UserProfieUpdate",
  async (body) => {
    const Formdata = new FormData();
    Formdata.append("first_name", body.user_first_name);
    Formdata.append("last_name", body.user_last_name);
    Formdata.append("email", body.user_email);
    Formdata.append("user_dob", body.user_dob);
    Formdata.append("bio", body.user_bio);
    Formdata.append("profile_completed", "0");
    Formdata.append("user_image", body.user_image[0]);

    try {
      const UserProfieUpdate = await Instance.post(
        "https://myhummingbird.net/index.php/user/update-user-profile",
        Formdata
      );

      return UserProfieUpdate.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

// Report api

export const ReportUSer = createAsyncThunk("ReportUSer", async (body) => {
  const Formdata = new FormData();
  Formdata.append("reported_id", body.UserId);
  Formdata.append("report", body.reportMsg);
  try {
    const ReportUSer = await Instance.post(
      "https://myhummingbird.net/index.php/report/report-user",
      Formdata
    );
    return ReportUSer.data;
  } catch (error) {
    return error.response.data;
  }
});

// Block USer

export const BlockUser = createAsyncThunk("BlockUser", async (u_id) => {
  try {
    const BlockUser = await Instance.get(
      `https://myhummingbird.net/index.php/user/block-user?block_id=${u_id}`
    );
    return BlockUser.data;
  } catch (error) {
    return error.response.data;
  }
});

// Unblock User
export const unblockUser = createAsyncThunk("unblockUser", async (u_id) => {
  try {
    const unblockUser = await Instance.get(
      `https://myhummingbird.net/index.php/user/unblock-user?blocked_id=${u_id}`
    );
    return unblockUser.data;
  } catch (error) {
    return error.response.data;
  }
});

// delete account Request
export const DeleteAccountReq = createAsyncThunk(
  "DeleteAccountReq",
  async () => {
    try {
      const DeleteAccountReq = await axios.get(
        `https://myhummingbird.net/index.php/user/request-account-delete`
      );
      return DeleteAccountReq.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// delete account
export const DeleteAccount = createAsyncThunk("DeleteAccount", async () => {
  const token = localStorage.getItem("authToken");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const DeleteAccount = await Instance.get(
      `https://myhummingbird.net/index.php/user/request-account-delete`,
      config
    );
    return DeleteAccount.data;
  } catch (error) {
    return error.response.data;
  }
});

// Add member in group
export const addMember = createAsyncThunk(
  "addMember",
  async (addMember, { rejectWithValue }) => {
    try {
      const addMember = await Instance.get(
        `https://myhummingbird.net/index.php/room/add-users?room_id${addMember?.roomId}&participants${addMember?.jsonSelectedDetails}`,
        {}
      );

      return addMember.data;
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Group name and image Edit

export const GroupProfieUpdate = createAsyncThunk(
  "GroupProfieUpdate",
  async (body) => {
    const Formdata = new FormData();
    Formdata.append("room_id", body.room_id);
    Formdata.append("room_name", body.room_name);
    Formdata.append("group_image", body.group_image[0]);
    try {
      const GroupProfieUpdate = await Instance.post(
        "https://myhummingbird.net/index.php/room/update-room",
        Formdata
      );

      return GroupProfieUpdate.data;
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

export const ChatUserSlice = createSlice({
  name: "ChatUserSlice",
  initialState,
  reducers: {
    OnlineUserList: (state, action) => {
      const userId = action.payload;
      if (!state.userOnline.includes(userId)) {
        state.userOnline.push(userId);
      }
    },

    offlineUser: (state, action) => {
      const userId = action.payload;
      if (state.userOnline.includes(userId)) {
        state.userOnline = state.userOnline.filter((id) => id !== userId);
      }
    },

    user_Typing: (state, action) => {
      const typingUserData = action.payload;

      const userExists = state.userTyping.some(
        (user) => user.id === typingUserData.id
      );

      // If not, add the new user
      if (!userExists) {
        state.userTyping.push(typingUserData);
      }
    },
  },
  extraReducers: (builder) => {
    //Login
    builder.addCase(loginEmploye.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loginEmploye.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;

      if (action.payload.status == true) {
        const token = action.payload.token;
        localStorage.setItem("authToken", token);
      }
    });

    builder.addCase(loginEmploye.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // otp Verfication
    builder.addCase(otpVerfication.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(otpVerfication.fulfilled, (state, action) => {
      state.isLoading = false;

      localStorage.setItem("m_sender_id", action.payload.user.user_id);
      state.otpVerficationStatus = action.payload;
    });

    builder.addCase(otpVerfication.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // FcmToken
    builder.addCase(Fcmtoken.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(Fcmtoken.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(Fcmtoken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    //Rooms
    builder.addCase(Room.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(Room.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rooms = action.payload;
    });
    builder.addCase(Room.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Groups
    builder.addCase(Groups.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(Groups.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Groups = action.payload;
    });
    builder.addCase(Groups.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    //Messages
    builder.addCase(Messages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(Messages.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Messages = action.payload;
    });
    builder.addCase(Messages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Send Messages
    builder.addCase(sendMessage.pending, (state) => {
      state.isLoading = true;
      state.sendMessageResponse = null;
    });

    builder.addCase(sendMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sendMessageResponse = action.payload;
    });

    builder.addCase(sendMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // send Scduled message
    builder.addCase(scheduleMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(scheduleMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.sendMessageResponse = action.payload;
    });
    builder.addCase(scheduleMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // get user details
    builder.addCase(Userprofile.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(Userprofile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.Userprofile = action.payload;
    });
    builder.addCase(Userprofile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // humming contacts
    builder.addCase(hummingContacts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(hummingContacts.fulfilled, (state, action) => {
      state.isLoading = false;

      state.hummingContacts = action.payload;
    });
    builder.addCase(hummingContacts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Group User Profile Details
    builder.addCase(groupUserDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(groupUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.groupUserDetails = action.payload;
    });

    builder.addCase(groupUserDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    //delete Reciver  message
    builder.addCase(deleteReciverMessages.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteReciverMessages.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteReciverMessages.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // delete paticular Room
    builder.addCase(deleteRoom.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(deleteRoom.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteRoom.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // All Scheduled  messages
    builder.addCase(allScheduledMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(allScheduledMessage.fulfilled, (state, action) => {
      state.isLoading = false;
      state.AllScheduledMessage = action.payload;
    });
    builder.addCase(allScheduledMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Api message

    builder.addCase(aiMessage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(aiMessage.fulfilled, (state, action) => {
      state.isLoading = false;

      state.Aimessage = action.payload;
    });
    builder.addCase(aiMessage.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Message seen Check
    builder.addCase(messageSeenCheck.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(messageSeenCheck.fulfilled, (state, action) => {
      state.isLoading = false;
      state.messageSeenCheck = action.payload;
    });
    builder.addCase(messageSeenCheck.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // User profile Update
    builder.addCase(UserProfieUpdate.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(UserProfieUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userProfileUpdate = action.payload;
    });

    builder.addCase(UserProfieUpdate.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Report User
    builder.addCase(ReportUSer.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(ReportUSer.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(ReportUSer.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Block USer

    builder.addCase(BlockUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(BlockUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(BlockUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Unblock User
    builder.addCase(unblockUser.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(unblockUser.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(unblockUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Delete account Request

    builder.addCase(DeleteAccountReq.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(DeleteAccountReq.fulfilled, (state, action) => {
      state.isLoading = false;
      state.DeleteAccountResponce = action.payload;
    });

    builder.addCase(DeleteAccountReq.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Delete Account

    builder.addCase(DeleteAccount.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(DeleteAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      state.DeletedAccountResponce = action.payload;
    });

    builder.addCase(DeleteAccount.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // add member
    builder.addCase(addMember.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addMember.fulfilled, (state, action) => {
      state.isLoading = false;
      state.addMember = action.payload;
    });

    builder.addCase(addMember.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // group profile update

    builder.addCase(GroupProfieUpdate.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(GroupProfieUpdate.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(GroupProfieUpdate.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { OnlineUserList, offlineUser, user_Typing } =
  ChatUserSlice.actions;
export default ChatUserSlice.reducer;
