import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import "../Styles/message.scss";
import { useDispatch } from "react-redux";
import { OnlineUserList } from "../Redux/Slice/ChatUserSlice";

const Testing = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectorWidth, setSelectorWidth] = useState('33.33%');

  const units = ['°C', '°F', '°K'];

  useEffect(() => {
      // Calculate selector width based on the number of units
      const width = 100 / units.length + '%';
      setSelectorWidth(width);
  }, [units.length]);

  const handleClick = (index) => {
      setSelectedIndex(index);
  };

  return (
    <>
       <div className="btn-fade">
            <div 
                className="selector" 
                style={{ width: selectorWidth, left: `${selectedIndex * parseFloat(selectorWidth)}%` }}
            />
            <div className="btn-content">
                {units.map((unit, index) => (
                    <div 
                        key={unit} 
                        className="btn-content-element" 
                        onClick={() => handleClick(index)}
                    >
                        <p className="noselect">{unit}</p>
                    </div>
                ))}
            </div>
        </div>
    </>
  );
};

export default Testing;
