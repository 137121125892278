import React ,{useEffect} from "react";
import logo from "../assets/Images/logo.png";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginEmploye } from "../Redux/Slice/ChatUserSlice";


import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { isLoading, data, error, response } = useSelector((state) => {
    return {
      isLoading: state?.user?.isLoading,
      data: state?.data,
      error: state?.user?.error,
      response: state?.user?.response,
    };
  });

  const alerts = response?.otp_generate;
  if (response?.status === true) {
    navigate("/otpVerification");
    toast.info("otp = " + alerts);
  }
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      navigate("/dashboard"); // or any other page you want to redirect to
    }
  }, [navigate]);

  const onSubmit = (data) => {
    dispatch(loginEmploye(data));
  };
  return (
    <>
      <div
        // style={{
        //   overflow: "hidden",
        //   height: "850px",
        
        //   marginTop: "30px",
        //   marginLeft: "20%",
        //   marginBottom: "20%",
        //   marginRight: "20%",
        //   marginTop: "35px",
        // }}
      >
        <img
          src={logo}
          alt=""
          class="rounded mx-auto d-block justify-content-center h-auto w-25 mt-0 "
        
        />

        <div class="container mt-0 d-flex justify-content-center mb-2" >
          <div class="row" >
            <div class="col-lg12">
              <div class="form container justify-content-center">
                <div class="inner-container">
                  <h2
                    style={{
                      marginTop: "0px",
                      fontSize: "40px",
                      color: "white",
                    }}
                  >
                    Enter Mobile Number
                  </h2>
                  <p
                    style={{
                      color: "white",
                      width: "100%",
                      fontSize: "30px",
                      fontStyle: "inherit",
                    }}
                  >
                    we will send you the 4 digit verifiaction code
                  </p>

                  <div class="input-group">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <label class="input-group__label" for="myInput">
                        +91 984524****
                      </label>
                      <input
                        type="number"
                        id="myInput"
                        name="numbers"
                        className={`input-group__input ${
                          errors.numbers && "error-border"
                        }`}
                        placeholder="91+"
                        style={{
                          fontWeight: "bold",
                          borderColor: errors?.numbers
                            ? "red solid"
                            : "transprent",
                        }}
                        {...register("numbers", {
                          required: true,
                          pattern: {
                            value: /^\d{10}$/, // Regular expression for 10-digit phone number
                            message:
                              "Please enter a valid 10-digit phone number",
                          },
                        })}
                      />
                      <div
                        style={{
                          display: "flex",
                          marginTop: "1px",
                          marginLeft: "450px",
                          color: "white",
                        }}
                      >
                        {errors.numbers && (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {errors.numbers.message}
                          </span>
                        )}
                      </div>

                      <button
                        className="btn btn-light "
                        type="submit"
                        id="btn"
                        style={{ backgroundColor: "white" }}
                      >
                        Send otp
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
