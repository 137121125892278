import React from 'react'

const DefaultPage = () => {
  return (
    <div className='chat justify-content-center  '  style={{backgroundColor:"#EBDCFE"}} >
      <div class="container" style={{marginTop:"23%"}} >
    <div class="row justify-content-center " >
      <div class="col-md-6 text-center hider ">
    
        <img className='Defaulf_image' src='https://visko.group/public/assets/img/uploads/ticketComment/logo1_1.png' alt='' style={{width:"100%", height:"auto"}}/>
        <h4>Schedule your message.</h4>
        <div className=' d-flex justify-content-center'>
       
        </div>
       
        <p class="mt-3"></p>
     </div>
    </div>
  </div>
    </div>
  )
}

export default DefaultPage
