import { useEffect } from "react";
// import Home from "./dashboard/Home";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import Error from "./Pages/Error";
import Testing from "./Pages/Testing";
import { initializeSocket } from "./Config/Socket/socket";
import Login from "./Pages/Login"
import "./App.css";
import OtpVerfiaction from "./Pages/OtpVerfication";
import { genrateToken, messaging } from "./Notification/Firbase";
import { Fcmtoken } from "./Redux/Slice/ChatUserSlice";
import { useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min";
import PrivateRoute from "./Routes/PrivateRoutes";
import Dashboard from "./DashBoard/Dashboard";
import OtpCheck from "./Pages/OtpCheck";

function App() {
 
  useEffect(() => {
    initializeSocket();
  }, []);

  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/otpVerification" element={<OtpCheck />} />
          <Route
            path="/dashboard"
            element={<PrivateRoute Component={Dashboard} />}
          />
          <Route path="/test" element={<PrivateRoute Component={Testing} />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
