import React, { useState, useEffect } from "react";
import "../../Styles/newpage.css";
import { UserProfieUpdate } from "../../Redux/Slice/ChatUserSlice";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";


const UserProfileUpdate = ({ profile, onClose }) => {
  const BASE_URL = "https://myhummingbird.net";
  const dispatch = useDispatch();

  // State to hold profile data and trigger re-renders
  const [profileData, setProfileData] = useState({
    user_first_name: profile.user_first_name,
    user_last_name: profile.user_last_name,
    user_bio: profile.user_bio,
    user_email: profile.user_email,
    user_dob: profile.user_dob,
    user_image: profile.user_image,
  });

  // State to force image re-renders by changing key
  const [imageUrlKey, setImageUrlKey] = useState(Date.now());
  const [newImage, setNewImage] = useState(null);

  // Handle image file change
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileData((prevState) => ({
          ...prevState,
          user_image:e.target.files,
        }));
        setImageUrlKey(reader.result); // Update image URL for display
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setProfileData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UserProfieUpdate(profileData));
    onClose();
  };

  // Construct image URL with the base URL and key
  const imageUrl = `${BASE_URL}${profileData.user_image}?key=${imageUrlKey}`;

  return (
    <div className="profile-update-container mt-0">
      <div className="profile-update-form">
        <div
          className="btn d-flex justify-content-end mb-2 mt-0 text-danger fs-2"
          onClick={onClose}
        >
          ×
        </div>
        <h3 className="text-center">Update Profile</h3>

        <div className="profile-update-img-container">
          {/* Ensure the image URL is updated when profileData.user_image changes */}
          <img
            src={imageUrl || profileData.user_image}
            alt="Profile"
            className="profile-update-img"
          />
          <label htmlFor="profileImageInput">
            <i className="bi bi-camera-fill"></i>
          </label>
          <input
            type="file"
            id="profileImageInput"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }} // Hide the input
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="user_first_name" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="user_first_name"
              value={profileData.user_first_name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="user_last_name" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="user_last_name"
              value={profileData.user_last_name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="user_bio" className="form-label">
              Bio
            </label>
            <input
              type="text"
              className="form-control"
              id="user_bio"
              value={profileData.user_bio}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="user_email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="user_email"
              value={profileData.user_email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="user_dob" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="user_dob"
              value={profileData.user_dob}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="btn btn-primary w-100">
            Update Profile
          </button>
        </form>
      </div>
    </div>
  );
};

UserProfileUpdate.propTypes = {
  profile: PropTypes.shape({
    user_first_name: PropTypes.string.isRequired,
    user_last_name: PropTypes.string.isRequired,
    user_bio: PropTypes.string.isRequired,
    user_email: PropTypes.string.isRequired,
    user_dob: PropTypes.string.isRequired,
    user_image: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};
export default UserProfileUpdate;
