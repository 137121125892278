import React, { useState, useEffect, useRef, Profiler } from "react";
import "../../Styles/Chat1.css";
import { toggleNav } from "../SideBar/Sidebar2";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { json, useNavigate } from "react-router-dom";
import defaultImage from "../../assets/Images/defaultImage.png";

import {
  Messages,
  Room,
  aiMessage,
  hummingContacts,
  user_Typing,
} from "../../Redux/Slice/ChatUserSlice";
import InputEmoji from "react-input-emoji";
import "../../Styles/message.scss";
import Loading from "../../Component/Loading/Loading";
import { sendMessage } from "../../Redux/Slice/ChatUserSlice";
import { scheduleMessage } from "../../Redux/Slice/ChatUserSlice";
//import { Modal } from "bootstrap";
import { messageSeenCheck } from "../../Redux/Slice/ChatUserSlice";
import { socketEventEmitter } from "../../Config/Socket/socket";
import { ClearChats } from "../../Redux/Slice/ChatUserSlice";
import { groupUserDetails } from "../../Redux/Slice/ChatUserSlice";
import { deleteReciverMessages } from "../../Redux/Slice/ChatUserSlice";
import { deleteSenderMessages } from "../../Redux/Slice/ChatUserSlice";
import { genrateToken, messaging } from "../../Notification/Firbase";
import { onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import Ai from "../../Component/Ai";
import ScheduleMessage from "../../Component/ScheduleMessage";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import {
  initializeSocket,
  getSocket,
  messageReceive,
} from "../../Config/Socket/socket";
import SettingNav from "../../Component/SettingNav";
import { calcLength } from "framer-motion";

import Button from "@mui/material/Button";

const Chat1 = ({
  showChat,
  isNavVisible,
  setShowClientInfo,
  setClientInfo,
  setNavVisible,
  setDeleteMyAccount,
  deleteMyAccount,
  setShowEditProfile,
  showEditProfile,
}) => {
  const [RepeatMessageValue, setRepeatMessageValue] = useState(0);
  const [allMessages, setAllMessages] = useState([]);
  const [Schedule, setschedule] = useState("0");
  const [s_date, sets_date] = useState("");
  const [s_time, sets_time] = useState("");
  const [checkForever, setcheckForever] = useState(false);
  const [RoomMessage, setRoomMessage] = useState("");
  const [schedualMesaage, setschedualMesaage] = useState("");
  const [recieveMessage, setRecieveMessage] = useState();
  const [receivedMessageId, setReceivedMessageId] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const dispatch = useDispatch();
  const sender_id = localStorage.getItem("m_sender_id");
  const socket = getSocket();
  const [isTyping, setIsTyping] = useState(false);
  const [typingUser, setTypingUser] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [selectedMessageId, setSelectedMessageId] = useState([]);
  const [reciverMessageIds, setreciverMessageIds] = useState([]);
  const [hummingUser, setHummingUser] = useState([]);
  const [paticulargroupdetail, setPaticulargroupdetail] = useState();
  const [notification, setNotification] = useState([]);
  const [enlargedSrc, setEnlargedSrc] = useState(null);
  const [isReplyVisible, setIsReplyVisible] = useState(false);
  const [isReplyButtonActive, setIsReplyButtonActive] = useState(false);
  const [replymsg, setReplymsg] = useState([]);
  const [replymsgRecive, SetreplymsgRecive] = useState([]);
  const typingTimeoutRef = useRef(null);
  const [isAiModalOpen, setAiModalOpen] = useState(false);
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [hummingAllContacts, setAllHummingContacts] = useState();
  const [updatedMessage, setUpdatedMessage] = useState([]);
  const [showInfo, setShowInfo] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [inputText, setInputText] = useState("");
  const [messageType, setMessageType] = useState(null);
  const [groupInfoId, setGroupInfoId] = useState([]);
  const fileInputRef = useRef(null);

  const navigate = useNavigate();
  const {
    Message,
    loading,
    MessageDetails,
    userTyping,
    SendMsgerror,
    sendMessageResponse,
    allHummingContacts,
    Paticulargroupdetails,
    aiMessage,
    MessageSeenCheck,
    msgResponse,
  } = useSelector((state) => {
    return {
      MessageDetails: state.user?.Messages,
      Message: state.user.Messages?.room?.messages,
      loading: state?.user?.isLoading,
      response: state.user?.response,
      error: state?.user?.error,
      userTyping: state?.user?.userTyping,
      sendMessageResponse: state?.user?.sendMessageResponse?.msg,
      allHummingContacts: state.user?.hummingContacts,
      Paticulargroupdetails: state.user?.groupUserDetails,
      aiMessage: state?.user?.Aimessage,
      MessageSeenCheck: state?.user?.messageSeenCheck?.msg,
      SendMsgerror: state?.user?.sendMessageResponse,
      msgResponse: state?.user?.sendMessageResponse,
    };
  });

  // useEffect(() => {
  //   let existingMessages = localStorage.getItem("allMessages");
  //   existingMessages = existingMessages ? JSON.parse(existingMessages) : [];
  //   existingMessages.push(Message);
  //   localStorage.setItem("allMessages", JSON.stringify(existingMessages));
  // }, [Message]);

  // get humming all contacts

  useEffect(() => {
    let hummingcontacts = localStorage.getItem("matchedContacts");
    setAllHummingContacts(hummingcontacts);
  }, []);

  // fetch paticular group  details

  useEffect(() => {
    setPaticulargroupdetail(Paticulargroupdetails.room);
  }, [Paticulargroupdetails]);

  {
    /* -------------------  reciever Id   */
  }

  const handleClickeve = () => {
    setIsHidden(!isHidden);
  };

  // typing user code

  let typingUserId = null;

  try {
    const r_users = MessageDetails?.room?.r_users;

    // Check if r_users is defined and a valid JSON string
    if (r_users && typeof r_users === "string") {
      const r_usersparsedArray = JSON.parse(r_users);

      // Check if the parsed result is an array and has at least one element
      if (Array.isArray(r_usersparsedArray) && r_usersparsedArray.length > 0) {
        // Find the first user ID that does not match the sender_id
        const typingUser = r_usersparsedArray.find(
          (userId) => userId !== sender_id
        );

        if (typingUser) {
          typingUserId = typingUser;
        } else {
          console.error(
            "No typing user ID found that does not match the sender_id."
          );
        }
      } else {
        console.error("Parsed r_users is not a valid array or is empty.");
      }
    } else {
      console.error("r_users is undefined, null, or not a valid JSON string.");
    }
  } catch (error) {
    console.error("Error parsing r_users:", error);
  }

  // new message push code by sender
  useEffect(() => {
    if (sendMessageResponse) {
      setAllMessages((prev) => [sendMessageResponse, ...prev]);
    }
  }, [sendMessageResponse]);

  // new message Recive by reciver

  useEffect(() => {
    // Define the event handler
    const handleMessageReceived = (data) => {
      setAllMessages((prev) => [data, ...prev]);
      setReceivedMessageId(data?.m_id);
      setRecieveMessage(data);
    };
    // Attach the event listener
    socketEventEmitter.on("message-received", handleMessageReceived);

    // Cleanup function to detach the event listener
    return () => {
      socketEventEmitter.off("message-received", handleMessageReceived);
    };
  }, []);

  // message seen status update by reciver

  useEffect(() => {
    if (receivedMessageId && recieveMessage) {
      console.log("Received Message ID:", receivedMessageId);
      const body = {
        m_id: receivedMessageId,
        message_status: "3",
      };
      dispatch(messageSeenCheck(body));
      if (MessageSeenCheck) {
        console.log(45);
        socket.emit("message-seen", MessageSeenCheck);
      }
    }
  }, [recieveMessage]);

  useEffect(() => {
    if (MessageSeenCheck) {
      socket.emit("message-seen", MessageSeenCheck);
    }
  }, [MessageSeenCheck]);

  // message seen status update by sender

  useEffect(() => {
    // Define the event handler
    const handleSeen = (data) => {
      setUpdatedMessage((prev) => [...prev, data]);
    };

    // Attach the event listener
    socket.on("seen", handleSeen);

    // Cleanup function to detach the event listener
    return () => {
      socket.off("seen", handleSeen);
    };
  }, []);

  // feth all message and push new array

  useEffect(() => {
    if (Message?.length > 0) {
      setAllMessages(Message);
    }
  }, [Message]);

  useEffect(() => {}, [allMessages]);

  useEffect(() => {
    if (showChat?.room_id !== null) {
      dispatch(Messages(showChat?.room_id));
    }
  }, [showChat]);

  useEffect(() => {
    if (msgResponse?.status === false) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You have blocked this person. Unblock them and then message them!",
      });
    }
  }, [msgResponse]);

  const handleSubmit = () => {
    let m_replied = null; // Initialize m_replied with null

    if (isReplyVisible && replymsg) {
      m_replied = replymsg.m_id; // Assign m_replied if replymsg is defined and visible
    }

    const body = {
      m_room_id: MessageDetails?.room.r_id,
      m_message_type: "0",
      m_message: RoomMessage,
      m_sender_id: sender_id,
      m_is_scheduled: "0",
      m_replied_on: m_replied, // Use the updated m_replied value
    };

    dispatch(sendMessage(body));
    // if (msgResponse?.status === false) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "You have blocked this person. Unblock them and then message them!",

    //   });
    // }
    setRoomMessage("");
    setIsReplyVisible(false);
  };

  const previewImageHandler = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // image priew

  const ImageSend = (e) => {
    let m_message_type;
    let m_media = e.target.files;

    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      if (file.type.startsWith("image/")) {
        m_message_type = "1";
      } else if (file.type.startsWith("audio/")) {
        m_message_type = "3";
      } else if (file.type.startsWith("video/")) {
        m_message_type = "4";
      }
      setMessageType(m_message_type);
      setSelectedFile(m_media);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSend = () => {
    console.log(2, selectedFile);
    var body = {
      m_room_id: MessageDetails?.room.r_id,
      m_message_type: messageType,
      m_message: inputText,
      m_media: selectedFile,
      m_sender_id: sender_id,
      m_is_scheduled: "0",
    };

    dispatch(sendMessage(body));
    setSelectedFile(null);
    setPreviewUrl(null);
    setInputText("");
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setInputText("");
  };

  useEffect(() => {
    setSelectedFile(null);
    setPreviewUrl(null);
    setInputText("");
  }, []);

  //image preview end

  //  ImageSend = (e) => {
  //   let m_message_type;
  //   let m_media = e.target.files;
  //   console.log(3,m_media)
  //   if (e.target.files && e.target.files[0]) {
  //     const file = e.target.files[0];
  //     if (file.type.startsWith("image/")) {
  //       m_message_type = "1";
  //     } else if (file.type.startsWith("audio/")) {
  //       m_message_type = "3";
  //     } else if (file.type.startsWith("video/")) {
  //       m_message_type = "4";
  //     }
  //   }
  //   var body = {
  //     m_room_id: MessageDetails?.room.r_id,
  //     m_message_type,
  //     m_message: "",
  //     m_media: e.target.files,
  //     m_sender_id: sender_id,
  //     m_is_scheduled: "0",
  //   };
  //   dispatch(sendMessage(body));
  // };

  useEffect(() => {
    // Initialize the socket connection

    // Get the socket instance
    const socket = getSocket();

    if (socket) {
      // Set up the "on-typing" event listener
      const handleTyping = (data) => {
        dispatch(user_Typing(data));
      };

      socket.on("on-typing", handleTyping);

      // Clean up the socket listener when the component unmounts
      return () => {
        socket.off("on-typing", handleTyping);
      };
    }
  }, [dispatch]); // Note: dispatch should be included in the dependency array

  // user typing emit

  useEffect(() => {
    return () => {
      // Clean up the typing timeout on component unmount
      clearTimeout(typingTimeoutRef.current);
    };
  }, []);

  const handleTyping = (e) => {
    if (socket) {
      socket.emit("typing", {
        roomId: showChat?.room_id,
        userId: sender_id,
        receiverId: typingUserId,
      });

      clearTimeout(typingTimeoutRef.current);

      typingTimeoutRef.current = setTimeout(() => {
        socket.emit("not-typing", {
          roomId: showChat?.room_id,
          userId: sender_id,
          receiverId: typingUserId,
        });
      }, 600);
    }
  };

  useEffect(() => {
    // Handle 'on-typing' event
    socket.on("on-typing", (data) => {
      setIsTyping(data.roomId);
      setTypingUser(data.username);
    });

    // Handle 'on-not-typing' event
    socket.on("on-not-typing", () => {
      setIsTyping(false);
      setTypingUser("");
    });

    // Cleanup socket listeners on component unmount
    return () => {
      socket.off("on-typing");
      socket.off("on-not-typing");
    };
  }, []);

  //get humming contacts
  useEffect(() => {
    try {
      const storedMatchedContacts =
        JSON.parse(localStorage.getItem("matchedContacts")) || [];
      if (storedMatchedContacts?.length > 0) {
        setHummingUser(storedMatchedContacts);
      }
    } catch (error) {
      console.error("Error parsing localStorage data:", error);
    }
  }, []);
  // end show typing or not

  //clear Chat
  const clearChat = () => {
    if (MessageDetails?.room?.messages) {
      const ids = MessageDetails.room?.messages?.map((data) => data.m_id);
      const messageIdJsonEncode = JSON.stringify(ids);

      // Dispatch the clear chat action
      dispatch(ClearChats(messageIdJsonEncode))
        .then(() => {
          // Clear the messages in local state after dispatch
          setAllMessages([]);
        })
        .catch((error) => {
          console.error("Failed to clear chat:", error);
        });
    }
  };

  const handleMessageClick = (messageId) => {
    setSelectedMessageId((prevSelectedMessageId) => {
      // Toggle selection: if present, remove it; if not, add it
      if (prevSelectedMessageId.includes(messageId)) {
        return prevSelectedMessageId.filter((id) => id !== messageId);
      } else {
        return [...prevSelectedMessageId, messageId];
      }
    });
  };

  const handleDeleteSelectedMessages = () => {
    if (!selectedMessageId || selectedMessageId?.length === 0) return;

    // Check if reciverMessageIds is empty
    const options =
      reciverMessageIds?.length === 0
        ? {
            title: "Are you sure?",
            text: `Do you want to delete ${selectedMessageId?.length} messages?`,
            icon: "warning",
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            denyButtonColor: "#6c757d",
            confirmButtonText: "Delete for everyone",
            denyButtonText: "Delete for me",
          }
        : {
            title: "Are you sure?",
            text: `Do you want to delete ${selectedMessageId?.length} messages?`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete for me",
          };

    Swal.fire(options).then((result) => {
      if (result.isConfirmed) {
        if (reciverMessageIds?.length === 0) {
          // User clicked 'Delete for everyone'
          const messageIdsJsonEncode = JSON.stringify(selectedMessageId);
          dispatch(
            deleteReciverMessages({
              messageList: messageIdsJsonEncode,
              delete_for: "1",
            })
          )
            .then(() => {
              Swal.fire(
                "Messages Deleted!",
                "Selected messages have been deleted for everyone.",
                "success"
              );
              // Clear selected messages after deletion
              setAllMessages((prevAllMessages) =>
                prevAllMessages.filter(
                  (message) => !selectedMessageId.includes(message.m_id)
                )
              );

              // Clear the selected message IDs
              setSelectedMessageId([]);
            })
            .catch((error) => {
              console.error("Error deleting messages:", error);
              Swal.fire("Error!", "Failed to delete messages.", "error");
            });
        } else {
          // User clicked 'Delete for me'
          const messageIdsJsonEncode = JSON.stringify(selectedMessageId);
          dispatch(
            deleteReciverMessages({
              messageList: messageIdsJsonEncode,
              delete_for: "0",
            })
          )
            .then(() => {
              Swal.fire(
                "Messages Deleted!",
                "Selected messages have been deleted for you.",
                "success"
              );
              // Clear selected messages after deletion
              setAllMessages((prevAllMessages) =>
                prevAllMessages.filter(
                  (message) => !selectedMessageId.includes(message.m_id)
                )
              );

              // Clear the selected message IDs
              setSelectedMessageId([]);
            })
            .catch((error) => {
              console.error("Error deleting messages:", error);
              Swal.fire("Error!", "Failed to delete messages.", "error");
            });
        }
      } else if (result.isDenied) {
        // Only for cases when reciverMessageIds is empty
        if (reciverMessageIds?.length === 0) {
          // User clicked 'Delete for me'
          const messageIdsJsonEncode = JSON.stringify(selectedMessageId);
          dispatch(
            deleteReciverMessages({
              messageList: messageIdsJsonEncode,
              delete_for: "0",
            })
          )
            .then(() => {
              Swal.fire(
                "Messages Deleted!",
                "Selected messages have been deleted for you.",
                "success"
              );
              // Clear selected messages after deletion
              setAllMessages((prevAllMessages) =>
                prevAllMessages.filter(
                  (message) => !selectedMessageId.includes(message.m_id)
                )
              );

              // Clear the selected message IDs
              setSelectedMessageId([]);
            })
            .catch((error) => {
              console.error("Error deleting messages:", error);
              Swal.fire("Error!", "Failed to delete messages.", "error");
            });
        }
      }
    });
  };

  // User Profile Deteails
  const userProfile = () => {
    const Room_Id = showChat?.room_id;
    dispatch(groupUserDetails(Room_Id));
  };

  // User Notification
  useEffect(() => {
    genrateToken();
    onMessage(messaging, (payload) => {
      setNotification((notification) => [
        ...notification,
        payload.notification,
      ]);
    });
  }, []);

  // show image full screen

  const handleImageClick = (src) => {
    setEnlargedSrc(src);
  };

  const closeEnlargedView = () => {
    setEnlargedSrc(null);
  };

  // message reply
  const handleReplyClick = (message) => {
    setReplymsg(message);
    setIsReplyVisible(true);
    setIsReplyButtonActive(true); // Set the reply button to active (blue) when clicked
  };

  const handleCloseReply = () => {
    setIsReplyVisible(false);
    setIsReplyButtonActive(false); // Reset the reply button to its default state
  };

  const handleDoubleClick = () => {
    setIsReplyVisible(false);
    setIsReplyButtonActive(false);
  };

  // Upadted messages

  const mergeMessages = (allMessages, updatedMessages) => {
    // Create a map for quick lookup of updated messages
    const updatedMessageMap = new Map(
      updatedMessages?.map((msg) => [msg.m_id, msg])
    );

    // Merge and prioritize updated messages
    const mergedMessages = allMessages?.map((msg) =>
      updatedMessageMap.has(msg.m_id) ? updatedMessageMap.get(msg.m_id) : msg
    );

    // Append new messages from updatedMessage not present in allMessages
    updatedMessages?.forEach((msg) => {
      if (!updatedMessageMap?.has(msg.m_id)) {
        mergedMessages.push(msg);
      }
    });

    return mergedMessages;
  };

  const mergedMessages = mergeMessages(allMessages, updatedMessage);

  //Updated messages end

  // Ai modal set
  const openAiModal = () => setAiModalOpen(true);
  const closeAiModal = () => setAiModalOpen(false);
  //Ai modal set end

  // Schedual Modal Open
  const openModal = () => setScheduleModalOpen(true);
  const closeModal = () => setScheduleModalOpen(false);

  // By default last messages
  const chatContainerRef = useRef(null);
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [allMessages, mergedMessages]);

  // INFO SHow modal
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);
  // Info End modal

  // logout User
  const logoutUser = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };
  //Logout user end

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Function to handle click on image and show full-screen
  const handleClickImage = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  // Function to close full-screen image
  const handleCloseFullScreen = () => {
    setFullScreenImage(null);
  };

  // show client details
  const handleClientInfo = (info) => {
    setClientInfo(info); // Update client information
    setShowClientInfo(true); // Show the ProfileSection
  };

  console.log("selectedMessageId", receivedMessageId);
  return (
    <>
      <div className="chat mb-5">
        {/* Side header */}

        {isNavVisible && (
          <div>
            {/* Side header */}
            <div>
              <div className="navtabs mb-2 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-lg-start">
                  <div className="navtab active" data-target="home">
                    <i
                      className="fa-solid fa-gear fs-4"
                      id="AccountOption"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    ></i>
                    <div
                      className="dropdown-menu mt-2 align-content-around"
                      aria-labelledby="AccountOption"
                    >
                      <a
                        className="dropdown-item fs-6 fw-semibold mb-1"
                        onClick={() => setShowEditProfile(true)}
                      >
                        <i className="fa-solid fa-user bg-success rounded-circle p-2 text-light"></i>{" "}
                        Profile Setting
                      </a>

                      <a
                        className="dropdown-item fs-6 fw-semibold mb-1"
                        onClick={() => setDeleteMyAccount(!deleteMyAccount)}
                      >
                        <i className="fa-solid fa-user-slash bg-danger rounded-circle p-2 text-light bg-info"></i>{" "}
                        Delete My Account
                      </a>
                    </div>
                  </div>
                  <div className="navtab" data-target="about">
                    <i className="fa-regular fa-bell fs-4"></i>
                  </div>
                  <div className="navtab" data-target="contact">
                    <i className="fa-solid fa-clock fs-4"></i>
                  </div>
                  <div className="navtab" data-target="page4">
                    <i className="fa-solid fa-share-nodes fs-4"></i>
                  </div>
                  <div className="navtab" data-target="page5">
                    <i className="fa-brands fa-hire-a-helper fs-4"></i>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-danger mr-4"
                    style={{
                      borderBottomLeftRadius: "15px",
                      borderTopRightRadius: "15px",
                    }}
                    onClick={logoutUser}
                  >
                    Logout <i className="fa-solid fa-right-from-bracket"></i>
                  </button>
                  <i
                    className="fa-solid fa-xmark fs-5 text-danger mb-5"
                    onClick={() => setNavVisible(!isNavVisible)}
                  ></i>
                </div>
              </div>
            </div>

            {/* Side header end */}
          </div>
        )}

        {/* Side headre end */}
        {/*header */}
        <div class="header d-flex align-items-center p-2">
          <div class="profile d-flex align-items-center">
            <img
              src={showChat?.userImage}
              alt="Profile Picture"
              class="rounded-circle me-2 "
              onClick={() => handleClickImage(showChat?.userImage)}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultImage;
              }}
            />
            <div class="user-info " onClick={() => handleClientInfo(showChat)}>
              <div class="user-name">{showChat.UserName}</div>
              <div class="user-status">
                {isTyping === showChat?.room_id ? (
                  <>
                    <p
                      style={{
                        color: "green",
                        fontStyle: "poppins",
                        marginTop: "2px",
                      }}
                    >
                      typing...
                    </p>
                  </>
                ) : (
                  <p className="mt-1 text-secondary fs-6">
                    {showChat.userNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div class="ms-auto action-icons d-flex align-items-center ">
            <button
              class="btn btn-link  p-1 me-2"
              onClick={handleDeleteSelectedMessages}
              style={{ color: "red", cursor: "pointer" }}
              hidden={selectedMessageId?.length === 0}
            >
              <i class="bi bi-trash text-danger fs-4 "></i>
            </button>

            <div id="ai" className=" mr-2 ">
              <span onClick={openAiModal} style={{ cursor: "pointer" }}>
                AI
              </span>
            </div>

            <div class="dropdown">
              <button
                class=" bg-transparent"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ borderColor: "transparent" }}
              >
                <i class="bi bi-three-dots-vertical fs-4"></i>
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item fs-5" onClick={clearChat}>
                  <i className="fa fa-trash p-1 text-danger"></i>
                  Clear Chat
                </a>
                {showChat?.roomType === "1" ? (
                  <>
                    <a class="dropdown-item fs-5">
                      <i className="fa fa-info p-1 text-info"></i>
                      info
                    </a>
                    <div>
                      <div></div>
                      <div></div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          <Ai isOpen={isAiModalOpen} onClose={closeAiModal} />
        </div>

        {/*chat-container */}

        <div className="chat-container" ref={chatContainerRef}>
          {previewUrl && (
            <div className="image-preview" onClick={handleCancel}>
              <button className="close-preview-button" onClick={handleCancel}>
                &times;
              </button>
              <img
                className="image-preview-img  w-75 h-75 rounded-0"
                src={previewUrl}
                alt="Enlarged"
              />
              <div
                className="message-input-container"
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="text"
                  className="message-input-field"
                  placeholder="Type your message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
                <button
                  className="send-message-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSend();
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          )}
          {allMessages?.length < 1 ? (
            <Loading />
          ) : (
            mergedMessages
              ?.slice()
              ?.reverse()
              ?.map((message, index) => {
                const isSender = message?.m_sender_id === sender_id;
                const messageType = message?.m_message_type;
                const contactDetail = hummingUser.find(
                  (data) => data.user_id === message?.m_sender_id
                );

                console.log("clf", hummingUser);

                const isHighlighted = selectedMessageId.includes(message.m_id);

                const messageStyle = {
                  backgroundColor: isHighlighted ? "lightblue" : "",
                  borderLeft: isHighlighted ? "5px solid blue" : "none",
                  padding: "10px",
                  margin: "10px 0",
                  borderRadius: "5px",
                };

                let repliedMessage = null;
                if (message.m_replied_on) {
                  repliedMessage = mergedMessages.find(
                    (msg) => msg.m_id === message.m_replied_on
                  );
                }

                let repliedSenderName = null;
                if (repliedMessage) {
                  const repliedSender = hummingUser.find(
                    (user) => user.user_id === repliedMessage.m_sender_id
                  );
                  repliedSenderName = repliedSender?.user_first_name || "you";
                }
                let displayDate;
                if (message.m_scheduled_at === "0") {
                  displayDate = new Date(message.m_created_at);
                } else {
                  displayDate = new Date(
                    parseInt(message.m_scheduled_at) * 1000
                  );
                }

                const seenBy = message?.m_seen_by
                  ? JSON.parse(message.m_seen_by)
                  : [];
                console.log("34", seenBy);

                console.log("message", message);

                return (
                  <>
                    <div
                      id="chatmsg"
                      key={index}
                      onDoubleClick={() => {
                        handleMessageClick(message.m_id);
                        if (message?.m_sender_id !== sender_id) {
                          setGroupInfoId((prevGroupInfoId) => {
                            if (prevGroupInfoId.includes(message.m_id)) {
                              return prevGroupInfoId.filter(
                                (id) => id !== message.m_id
                              );
                            } else {
                              return [...prevGroupInfoId, message.m_id];
                            }
                          });

                          setreciverMessageIds((prevSelectedMessageId) => {
                            if (prevSelectedMessageId.includes(message.m_id)) {
                              return prevSelectedMessageId.filter(
                                (id) => id !== message.m_id
                              );
                            } else {
                              return [...prevSelectedMessageId, message.m_id];
                            }
                          });
                        }
                      }}
                      style={messageStyle}
                    >
                      {isSender ? (
                        <div className="message-box my-message">
                          <p>
                            <div className="d-flex justify-content-end mb-2">
                              {/* <i
                                class="fa-solid fa-chevron-right text-secondary pull-left"
                                data-toggle="modal"
                                data-target="#msgInfo"
                              ></i> */}
                              {/* info Modal */}

                              {showChat?.roomType === "1" ? (
                                <>
                                  <div class="custom-tooltip-container">
                                    <i
                                      class="fa-solid fa-chevron-right custom-icon text-secondary text-small fs-5"
                                      data-toggle="modal"
                                      data-target="#msgInfo"
                                    ></i>
                                    <div class="custom-tooltip-text">
                                      Message Info
                                    </div>
                                  </div>
                                </>
                              ) : null}

                              <div
                                id="msgInfo"
                                class="modal fade"
                                tabindex="-1"
                                role="dialog"
                              >
                                <div class="modal-dialog" role="document">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h5 class="modal-title">Message Info</h5>
                                      <button
                                        type="button"
                                        class="close  text-danger"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                    <div class="modal-body">
                                      <h3 className="text-dark fw-bolder ">
                                        Seen By
                                      </h3>

                                      {seenBy.length > 0 ? (
                                        seenBy.map((display) => {
                                          const user = hummingUser.find(
                                            (user) => user.user_id === display
                                          );
                                          if (user) {
                                            const BASE_URL =
                                              "https://myhummingbird.net";
                                            const imageUrl = `${BASE_URL}${user?.user_image}`;
                                            return (
                                              <ul
                                                className="list-group"
                                                key={user?.user_id}
                                              >
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="rounded-circle member-icon"
                                                      src={imageUrl}
                                                      alt="Loading.."
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                      }}
                                                    />
                                                    <div className="ml-3">
                                                      <h5>
                                                        {user?.user_first_name}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                  <span className="badge badge-success badge-pill">
                                                    admin
                                                  </span>
                                                </li>
                                              </ul>
                                            );
                                          }
                                          return null;
                                        })
                                      ) : (
                                        <p>No one has seen this message yet.</p>
                                      )}

                                      <hr />
                                    </div>
                                    <div class="modal-footer">
                                      <button
                                        type="button"
                                        class="btn btn-secondary"
                                        data-dismiss="modal"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Info Modal end */}
                            </div>

                            {messageType === "0" ? (
                              <>
                                {" "}
                                {repliedMessage && (
                                  <>
                                    <div className="reply">
                                      <span className="side-line "></span>
                                      <div className="payment ">
                                        {repliedSenderName ? (
                                          <div className="name text-dark fw-semibold fs-6">
                                            {repliedSenderName}
                                            <i
                                              class="fa fa-reply text-dark ml-2"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        ) : null}

                                        <div className="amount"></div>
                                      </div>
                                      <div className="paid">
                                        {repliedMessage?.m_message}
                                      </div>
                                    </div>
                                  </>
                                )}
                                {message?.m_message}
                              </>
                            ) : messageType === "1" ? (
                              <>
                                <img
                                  className="img-fluid rounded-0"
                                  style={{ height: "400px", width: "400px" }}
                                  src={`https://myhummingbird.net/${message.m_media}`}
                                  alt="Preview"
                                  onClick={() =>
                                    handleImageClick(
                                      `https://myhummingbird.net/${message.m_media}`
                                    )
                                  }
                                />
                                {message?.m_message !== "" ? (
                                  <p className=" mb-0">{message?.m_message}</p>
                                ) : null}

                                {enlargedSrc && (
                                  <div
                                    className="enlarged-view"
                                    onClick={closeEnlargedView}
                                  >
                                    <button
                                      className="close-button"
                                      onClick={closeEnlargedView}
                                    >
                                      &times;
                                    </button>
                                    <img
                                      className="img-fluid  rounded-0"
                                      src={enlargedSrc}
                                      alt="Enlarged"
                                    />
                                  </div>
                                )}
                              </>
                            ) : messageType === "4" ? (
                              <>
                                <p>
                                  {" "}
                                  <video height="400px" width="400px" controls>
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="video/mp3"
                                    />
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="video/mp4"
                                    />
                                  </video>
                                </p>
                              </>
                            ) : messageType === "3" ? (
                              <>
                                <p>
                                  <audio controls>
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="audio/ogg"
                                    />
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="audio/mpeg"
                                    />
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="audio/mp4"
                                    />
                                    <source
                                      src={`https://myhummingbird.net/${message.m_media}`}
                                      type="audio/mp3"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                </p>
                              </>
                            ) : null}
                            <br />
                            <div style={{ textAlign: "right" }}>
                              <span
                                id="reply"
                                className={`fa-solid fa-reply p-2 fs-5 ml-5 text-light ${
                                  isReplyButtonActive ? "reply-active" : ""
                                }`}
                                onClick={() => handleReplyClick(message)}
                                onDoubleClick={handleDoubleClick}
                              ></span>
                              <span>
                                {displayDate.toLocaleString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                              {isSender && (
                                <>
                                  {message.m_read_status === "0" ? (
                                    <i class="bi bi-stopwatch text-light p-2"></i>
                                  ) : message.m_read_status === "1" ? (
                                    <i
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        color: "white",
                                      }}
                                      className="fa-solid fa-check"
                                    ></i>
                                  ) : message.m_read_status === "2" ? (
                                    <i
                                      className="fa-solid fa-check-double text-secondary "
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "13px",
                                      }}
                                    ></i>
                                  ) : message.m_read_status === "3" ? (
                                    <svg
                                      style={{
                                        marginLeft: "10px",
                                        fontSize: "28px",
                                      }}
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="19"
                                      id="msg-dblcheck-ack"
                                      x="2063"
                                      y="2076"
                                    >
                                      <path
                                        d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
                                        fill="#4fc3f7"
                                      />
                                    </svg>
                                  ) : messageType === "2" ? (
                                    <div className="document-preview">
                                      <iframe
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        className="document-preview-iframe"
                                        width="100%"
                                        height="500px"
                                      />
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </div>
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="message-box friend-message">
                            <p>
                              {messageType === "0" ? (
                                <>
                                  {" "}
                                  {showChat?.roomType === "1" ? (
                                    <h6 className="text-light">
                                      {contactDetail?.user_first_name}
                                    </h6>
                                  ) : null}
                                  {repliedMessage && (
                                    <>
                                      <div className="reply">
                                        <span className="side-line "></span>
                                        <div className="payment ">
                                          <div className="amount"></div>
                                        </div>
                                        <div className="paid">
                                          {repliedMessage?.m_message}
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {message?.m_message}
                                </>
                              ) : messageType === "1" ? (
                                <>
                                  <img
                                    className="img-fluid rounded-0"
                                    style={{ height: "400px", width: "400px" }}
                                    src={`https://myhummingbird.net/${message.m_media}`}
                                    alt="Preview"
                                    onClick={() =>
                                      handleImageClick(
                                        `https://myhummingbird.net/${message.m_media}`
                                      )
                                    }
                                  />
                                  {enlargedSrc && (
                                    <div
                                      className="enlarged-view"
                                      onClick={closeEnlargedView}
                                    >
                                      <button
                                        className="close-button"
                                        onClick={closeEnlargedView}
                                      >
                                        &times;
                                      </button>
                                      <img
                                        className="img-fluid h-75 w-75 rounded-0"
                                        src={enlargedSrc}
                                        alt="Enlarged"
                                      />
                                    </div>
                                  )}
                                </>
                              ) : messageType === "4" ? (
                                <>
                                  <p>
                                    {" "}
                                    <video
                                      height="400px"
                                      width="400px"
                                      controls
                                    >
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="video/mp3"
                                      />
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </p>
                                </>
                              ) : messageType === "3" ? (
                                <>
                                  <p>
                                    <audio controls>
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="audio/ogg"
                                      />
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="audio/mpeg"
                                      />
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="audio/mp4"
                                      />
                                      <source
                                        src={`https://myhummingbird.net/${message.m_media}`}
                                        type="audio/mp3"
                                      />
                                      Your browser does not support the audio
                                      element.
                                    </audio>
                                  </p>
                                </>
                              ) : messageType === "2" ? (
                                <div className="document-preview">
                                  <iframe
                                    src={`https://myhummingbird.net/${message.m_media}`}
                                    className="document-preview-iframe"
                                    width="100%"
                                    height="500px"
                                  />
                                </div>
                              ) : null}
                              <div style={{ textAlign: "right" }}>
                                <span
                                  id="reply"
                                  className={`fa-solid fa-reply p-2 fs-5 ml-5 text-light ${
                                    isReplyButtonActive ? "reply-active" : ""
                                  }`}
                                  onClick={() => handleReplyClick(message)}
                                  onDoubleClick={handleDoubleClick}
                                ></span>
                                <span>
                                  {displayDate.toLocaleString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </span>
                              </div>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })
          )}

          {fullScreenImage && (
            <div className="fullscreen-overlay">
              <div className="fullscreen-content">
                <button
                  className="close-button"
                  onClick={handleCloseFullScreen}
                >
                  &times;
                </button>
                <img
                  src={fullScreenImage}
                  alt="Full Screen"
                  className="fullscreen-image rounded-1 img-fluid "
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )}
        </div>

        {/*input-bottom */}

        <div className="position-relative input-container ">
          {isReplyVisible && (
            <div className="reply-message">
              <div className="d-flex justify-content-between align-items-center">
                <div className="reply-text">{replymsg.m_message}</div>
                <button
                  className="btn btn-close-reply"
                  onClick={handleCloseReply}
                >
                  ✖
                </button>
              </div>
            </div>
          )}

          <div className="d-flex align-items-end bg-white input-wrapper">
            <div className="p-4 d-flex">
              <label
                id="inputButton"
                className="custom-file-upload btn btn-icon btn-light d-inline-block btn-md btn-pill btn-circle"
              >
                <input type="file" ref={fileInputRef} onChange={ImageSend} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-plus-lg"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                  ></path>
                </svg>
              </label>

              <label
                id="inputButton"
                type="button"
                className=" custom-file-upload btn btn-icon btn-light btn-md btn-pill"
                onClick={openModal}
                // data-bs-toggle="modal"
                // data-bs-target="#schedualMesaage"
                style={{ marginLeft: "10px", marginRight: "0px" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  className="bi bi-stopwatch"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
                  <path d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3" />
                </svg>
              </label>

              <ScheduleMessage
                isOpen={isScheduleModalOpen}
                onClose={closeModal}
              />
            </div>
            <div className="p-3 w-100">
              <div
                className="flex-grow-1 d-flex mb-2"
                style={{ color: "yellow" }}
              >
                <InputEmoji
                  value={RoomMessage}
                  onChange={setRoomMessage}
                  cleanOnEnter
                  onEnter={handleSubmit}
                  placeholder="Type a message..."
                  onKeyDown={handleTyping}
                />
                <div className="p-2 d-flex">
                  <img
                    onClick={(e) => handleSubmit()}
                    style={{ marginTop: "4px", height: "30px" }}
                    src="https://cdn-icons-png.flaticon.com/128/4024/4024582.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* info modal  */}

        {/* Info modal end */}
      </div>
    </>
  );
};

export default Chat1;
