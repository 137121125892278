import React from "react";

const Loading = () => {
  return (
    <div>
      <div class="spinner" style={{position:"fixed"}}>
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>

        {/* <div className=" fw-bold fs-6 ">Loading...</div> */}
      </div>
    </div>
  );
};

export default Loading;
