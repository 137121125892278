import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import animation from "../assets/ailoading.json";
import { aiMessage } from "../Redux/Slice/ChatUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../Redux/Slice/ChatUserSlice";
import ScheduleMessage from "./ScheduleMessage";

// const Ai = ({ isOpen, onClose }) => {
//   const [hover, setHover] = React.useState(false);
//   const [isAnimating, setIsAnimating] = useState(false);
//   const [inputValue, setInputValue] = useState("");
//   const [Aimessage, setAimessage] = useState("");
//   const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
//   const lottieRef = useRef(null);
//   const dispatch = useDispatch();

//   const { aiMessages, MessageDetails, loading } = useSelector((state) => ({
//     MessageDetails: state.user?.Messages,
//     aiMessages: state?.user?.Aimessage,
//     loading: state?.user?.loading,
//   }));

//   useEffect(() => {
//     setAimessage(aiMessages);
//   }, [aiMessages]); // Update Aimessage whenever aiMessages changes

//   if (loading === true) {
//     setIsAnimating(true);
//   }

//   const handleSendClick = () => {
//     dispatch(aiMessage(inputValue));

//     setInputValue("");
//     lottieRef.current.goToAndPlay(0); // Restart the animation
//   };

//   const handleScheduleClick = () => {
//     setIsAnimating(false);
//     lottieRef.current.stop(); // Stop the animation
//   };
//   const closeScheduleModal = () => {
//     setScheduleModalOpen(false);
//   };

//   const aimessage = (e) => {
//     setInputValue(e.target.value);
//   };

//   const handleSendMessage = () => {
//     const sender_id = localStorage.getItem("m_sender_id");

//     const body = {
//       m_room_id: MessageDetails?.room.r_id,
//       m_message_type: "0",
//       m_message: Aimessage.message,
//       m_sender_id: sender_id,
//       m_is_scheduled: "0",
//       m_replied_on: null,
//     };

//     dispatch(sendMessage(body));
//     setInputValue(""); // Clear the input value
//     onClose(); // Close the modal
//   };

//   if (!isOpen) return null;

//   return (
//     <div style={overlayStyle}>
//       <div style={modalStyle}>
//         <button onClick={onClose} style={closeButtonStyle}>
//           ×
//         </button>
//         <h5 className="text-info">Generate Message With AI</h5>
//         <div style={searchboxwrap}>
//           <input
//             type="text"
//             placeholder="Enter what you want to generate..."
//             style={inputStyle}
//             onChange={aimessage}
//             value={inputValue}
//           />
//           <button
//             style={buttonStyle}
//             onMouseEnter={() => setHover(true)}
//             onMouseLeave={() => setHover(false)}
//             onClick={handleSendClick}
//           >
//             <span
//               style={hover ? { ...spanStyle, ...spanHoverStyle } : spanStyle}
//             >
//               Send
//             </span>
//           </button>
//         </div>
//         <p className="mt-5 text-dark fw-bolder">
//           Generate Custom Message with the power of AI
//         </p>
//         <div>
//           {aiMessages.message && (
//             <p className="text-info">{aiMessages.message}</p>
//           )}
//           {/* Lottie Animation */}
//           <Lottie
//             animationData={animation}
//             loop={false} // Adjust loop as needed
//             autoplay={isAnimating}
//             lottieRef={lottieRef}
//           />
//         </div>
//         <div className="d-flex justify-content-center">
//         <button className="btn btn-info btn-lg mx-2" onClick={handleScheduleClick}>
//         Schedule
//       </button>
//           <button
//             className="btn btn-info btn-lg mx-2"
//             onClick={handleSendMessage}
//           >
//             Send
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

const Ai = ({ isOpen, onClose }) => {
  const [hover, setHover] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [Aimessage, setAimessage] = useState("");
  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const lottieRef = useRef(null);
  const dispatch = useDispatch();

  const { aiMessages, MessageDetails, loading } = useSelector((state) => ({
    MessageDetails: state.user?.Messages,
    aiMessages: state?.user?.Aimessage,
    loading: state?.user?.loading,
  }));

  useEffect(() => {
    setAimessage(aiMessages);
  }, [aiMessages]);

  useEffect(() => {
    if (loading) {
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [loading]);

  const handleSendClick = () => {
    dispatch(aiMessage(inputValue));
    setInputValue("");
    lottieRef.current.goToAndPlay(0);
  };

  const handleScheduleClick = () => {
    setIsAnimating(false);
    lottieRef.current.stop();
    setScheduleModalOpen(true);
  };

  const closeScheduleModal = () => {
    setScheduleModalOpen(false);
  };

  const aimessage = (e) => {
    setInputValue(e.target.value);
  };

  const handleSendMessage = () => {
    const sender_id = localStorage.getItem("m_sender_id");

    const body = {
      m_room_id: MessageDetails?.room.r_id,
      m_message_type: "0",
      m_message: Aimessage.message,
      m_sender_id: sender_id,
      m_is_scheduled: "0",
      m_replied_on: null,
    };

    dispatch(sendMessage(body));
    setInputValue("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <button onClick={onClose} style={closeButtonStyle}>
          ×
        </button>
        <h5 className="text-info">Generate Message With AI</h5>
        <div style={searchboxwrap}>
          <input
            type="text"
            placeholder="Enter what you want to generate..."
            style={inputStyle}
            onChange={aimessage}
            value={inputValue}
          />
          <button
            style={buttonStyle}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleSendClick}
          >
            <span
              style={hover ? { ...spanStyle, ...spanHoverStyle } : spanStyle}
            >
              Send
            </span>
          </button>
        </div>
        <p className="mt-5 text-dark fw-bolder">
          Generate Custom Message with the power of AI
        </p>
        <div>
          {aiMessages.message && (
            <p className="text-info">{aiMessages.message}</p>
          )}
          <Lottie
            animationData={animation}
            loop={false}
            autoplay={isAnimating}
            lottieRef={lottieRef}
          />
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-info btn-lg mx-2"
            onClick={handleScheduleClick}
          >
            Schedule
          </button>
          <button
            className="btn btn-info btn-lg mx-2"
            onClick={handleSendMessage}
          >
            Send
          </button>
        </div>
        {isScheduleModalOpen && (
          <ScheduleMessage
            isOpen={isScheduleModalOpen}
            onClose={closeScheduleModal}
            initialMessage={aiMessages.message}
          />
        )}
      </div>
    </div>
  );
};





const buttonStyle = {
  paddingRight: "10px",
  backgroundColor: "#fff",
  borderTopRightRadius: "25px",
  borderBottomRightRadius: "25px",
  boxShadow: "5px 4px 6px rgba(0, 0, 0, 0.19)",
  border: "none",
  cursor: "pointer",
};

const spanStyle = {
  marginLeft: "50px",
  padding: "14px 10px",
  fontSize: "0.9em",
  textTransform: "uppercase",
  fontWeight: 300,
  color: "#fff",
  backgroundColor: "#65587b ",
  borderRadius: "20px",
  boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.19)",
  fontWeight: "bold",
};

const spanHoverStyle = {
  backgroundColor: "#201634",
  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.19)",
};

const inputStyle = {
  flex: 1,
  padding: "30px 20px",
  fontSize: "1.1em",
  borderTopLeftRadius: "25px",
  borderBottomLeftRadius: "25px",
  boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.19)",
  border: "none",
};

const searchboxwrap = {
  display: "flex",
  width: "450px",
  marginTop: "8%",
};

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2000,
};

const modalStyle = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "8px",
  width: "500px",
  boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
  position: "relative",
};

const closeButtonStyle = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "none",
  border: "none",
  fontSize: "25px",
  cursor: "pointer",
  color: "red",
  fontWeight: "bold",
};

export default Ai;
