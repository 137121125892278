import React,{useEffect} from "react";
import logo from "../assets/Images/logo.png";
import "../Styles/Login.scss";
import { Box, keyframes } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { otpVerfication } from "../Redux/Slice/ChatUserSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const inputFocusAnimation = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
`;

const OtpCheck = () => {
  const [otp, setOtp] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, data, error, response, otpVerficationStatus } =
    useSelector((state) => {
      return {
        isLoading: state?.user?.isLoading,
        data: state?.data,
        error: state?.user?.error,
        response: state?.user?.response,
        otpVerficationStatus: state?.user?.otpVerficationStatus,
      };
    });
  console.log(445, otpVerficationStatus);

  useEffect(() => {
    if (otpVerficationStatus?.status === true) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: `${otpVerficationStatus?.message}`,
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/dashboard");
    } else if (otpVerficationStatus?.status === false) {
      toast.info("Wrong Otp");
    }
  }, [otpVerficationStatus]);

  const handleChange = (newValue) => {
    setOtp(newValue);

  };

  const handleVerify = () => {
    dispatch(otpVerfication(otp));
    setOtp("")
  };

  return (
    <div>
      <div
        style={{
          overflow: "hidden",
          height: "850px",
          marginTop: "35px",
          marginLeft: "25%",
          marginBottom: "20%",
          marginRight: "20%",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          className="rounded mx-auto d-block h-25 w-50"
        />

        <div className="container" style={{ marginTop: "0px" }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form container justify-content-center">
                <div className="inner-container">
                  <h2
                    style={{
                      marginTop: "0px",
                      fontSize: "40px",
                      color: "white",
                    }}
                  >
                    Enter Otp Number
                  </h2>
                  <p
                    style={{
                      color: "white",
                      width: "100%",
                      fontSize: "30px",
                      fontStyle: "inherit",
                    }}
                  >
                    Enter the OTP you received at{" "}
                    <span style={{ fontSize: "25px" }}>+91 9111125002</span>
                  </p>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px",
                    }}
                  >
                    <MuiOtpInput
                      value={otp}
                      onChange={handleChange}
                      sx={{
                        width: "250px",
                        "& .MuiInputBase-input": {
                          backgroundColor: "#fff",
                          borderRadius: "8px",
                          padding: "12px",
                          border: "2px solid #ccc",
                          transition: "all 0.3s ease-in-out",
                          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                          ":focus": {
                            borderColor: "#3f51b5",
                            animation: `${inputFocusAnimation} 0.6s ease-in-out`,
                          },
                          ":hover": {
                            borderColor: "#3f51b5",
                          },
                        },
                      }}
                    />
                  </Box>

                  <button
                    className="btn btn-light bg-light mt-5"
                    type="submit"
                    id="btns"
                    onClick={handleVerify}
                  >
                    Verify Otp
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpCheck;
