import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PrivateRoute = ({ Component }) => {
  const navigate = useNavigate();
  const login = localStorage.getItem("authToken");
  useEffect(() => {
 
    if (!login) {
      navigate("/");
    }
  }, [navigate]);

  return login ? <Component /> : null;
};


export default PrivateRoute;
